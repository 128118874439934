import { ENV } from '../../../constants/environments';
import { AUTH_ACTIONS } from '../../../constants/actions';
const { AUTH_ON_SUBSCRIBED, AUTH_USER_INFO_OK } = AUTH_ACTIONS;

import outdatedPlanIds from '../../../staticData/outdatedPlanVariantIds';

import subscriptionUpgradeHelpers from '../../subscriptionUpgradeHelpers';
import userHelpers from '../../user';

import axios, { AxiosError, AxiosResponse } from 'axios';
import errorRedirectHandler from '../../../helpers/errorRedirectHandler';
import Stripe from 'stripe';
import store from '../../../state/store';

import { clearPlanVariants } from '../../../state/actions/planVariantsCacheActions';

type Store = typeof store;
type Dispatch = Store['dispatch'];

export const stripe = new Stripe(process.env.STRIPE_API_KEY, { apiVersion: '2022-11-15' });

import { GetServerSidePropsContext } from 'next';
import { SessionState } from '../../../types/state/reducers/authReducersTypes';
import { RedirectConfigProps } from '../../../types/pages/generic/redirectConfig';
import { GenericResponse } from '../../../types/api/Http';
import {
  ConfirmSubscriptionOrderResponse,
  SubscriptionDetail,
  CreditPlanConfig,
  CreateSubscriptionPayload,
  PlanVariantResponse,
} from '../../../types/api/SubscriptionTypes';
import {
  PurchaseCreditPackPayload,
  PurchaseCustomCreditPackPayload,
} from '../../../types/api/CreditsTypes';
import { CheckoutAuthServerSideProps } from '../../../types/pages/checkout/subscribe/id';
import { ServerSideProps } from '../../../types/pages/generic/serverSideProps';
import { StripePaymentMethodsList } from '../../../types/api/stripe/paymentMethodsList';
import { User } from '../../../types/api/UsersTypes';
import { SelectedCreditOptionConfig } from '../../../types/components/credits/BuyCreditsOptions';
import { LicenseId } from '../../../types/api/LicenseTypesTypes';
import { LicenseTypeId } from '../../../types/api/LicenseTypesTypes';

const apiUrl: string = `${ENV.api.baseURL}`;

const fetchSubscriptionCheckoutServerSideProps = async (
  auth: SessionState,
  ctx: GetServerSidePropsContext,
): Promise<RedirectConfigProps | ServerSideProps<CheckoutAuthServerSideProps>> => {
  const { params, query } = ctx;
  const { license_id } = query;
  const { getMinimalLicenseIdForUpgrade } =  subscriptionUpgradeHelpers;
  let selectedPlan: CreditPlanConfig;

  const user = auth?.user;
  const subscription = auth?.subscription;
  const plan_id = params.id;

  const licenseId: LicenseTypeId = parseInt(license_id as string) as LicenseTypeId;
  const minimalAllowedLicenseId = getMinimalLicenseIdForUpgrade(user as User, subscription) as LicenseId;

  const redirectToPricingProps: RedirectConfigProps = {
    redirect: {
      destination: '/pricing',
      permanent: false,
    },
  };
  const redirectToEnterprisePricingProps: RedirectConfigProps = {
    redirect: {
      destination: `/pricing/enterprise?license_id=${license_id}`,
      permanent: false,
    },
  };

  const planVariantsUrl = `${apiUrl}/plan_variants?license_id=${licenseId}`;
  try {
    const planVariantsAvailableForLicenseResponse: GenericResponse<Array<PlanVariantResponse>> = await axios.get(planVariantsUrl, {
      headers: { authorization: user?.token }
    });
    const planVariantsAvailableForLicense = planVariantsAvailableForLicenseResponse.data;
    const selectedPlanVariantCanBeUsedWithLicense = !!planVariantsAvailableForLicense.find(
      plan => plan.id === parseInt(plan_id as string)
    );
    if (!selectedPlanVariantCanBeUsedWithLicense)
      return licenseId < 3 ? redirectToPricingProps : redirectToEnterprisePricingProps;
  } catch (e) {
    // fallback
    const { individualOrStartup, enterprise } = outdatedPlanIds;
    if (individualOrStartup.find(id => id === parseInt(plan_id as string)))
      return redirectToPricingProps;

    if (enterprise.find(id => id === parseInt(plan_id as string)))
        return redirectToEnterprisePricingProps;
  }

  if (
    licenseId < minimalAllowedLicenseId ||
    subscription && subscription.plan_variant.id === parseInt(plan_id as string)
  ) {
    const redirectProps: RedirectConfigProps = {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
    return redirectProps;
  }

  try {
    const plan_url = `${apiUrl}/plan_variants/${plan_id}?license_id=${licenseId}`;
    const selectedPlanResponse = await axios.get(plan_url, {
      headers: { authorization: user?.token }
    });
    selectedPlan = selectedPlanResponse.data;
  } catch (e) {
    const redirectProps: RedirectConfigProps = errorRedirectHandler(e);
    return redirectProps;
  }

  if (!user || !user?.stripe_customer_token) {
    const props: CheckoutAuthServerSideProps = { selectedPlan };
    return { props };
  }
  try {
    const stripePaymentMethods = await stripe.customers.listPaymentMethods(auth.user.stripe_customer_token) as any as StripePaymentMethodsList;
    const props: CheckoutAuthServerSideProps = { stripePaymentMethods, selectedPlan };
    return { props };
  } catch (e) {
    const redirectProps: RedirectConfigProps = errorRedirectHandler(e);
    return redirectProps;
  }
}

const afterSubscribe = async (
  user: User,
  selectedPlan: CreditPlanConfig | PlanVariantResponse,
  newSubscription: SubscriptionDetail,
  dispatch: Dispatch,
  selectedLicenseId: LicenseId,
  previousSubscription?: SubscriptionDetail,
) => {
  const updatedUserInfo = await userHelpers.loadUserInfo(user);
  dispatch({
    type: AUTH_USER_INFO_OK,
    payload: {
      user: { ...updatedUserInfo.user, token: user.token },
      subscription: newSubscription,
      authenticated: true,
    }
  });
  dispatch(clearPlanVariants());
  const { isUpgradeOrDowngrade } = subscriptionUpgradeHelpers;
  if (
    previousSubscription &&
    isUpgradeOrDowngrade(
      selectedPlan as PlanVariantResponse,
      { id: selectedLicenseId},
      previousSubscription
    ) === 'downgrade'
  ) return;
  dispatch({ type: AUTH_ON_SUBSCRIBED, subscription: newSubscription });
}

const subscribe = async (
  user: User,
  planVariant: CreditPlanConfig | PlanVariantResponse,
  setup_intent_id: string,
  selectedLicenseId: LicenseId,
  dispatch: Dispatch,
  currentPlan?: SubscriptionDetail,
  addOn?: SelectedCreditOptionConfig,
  payment_intent_id?: string,
  discount_code?: string
): Promise<ConfirmSubscriptionOrderResponse | AxiosError> => {
  const url = `${apiUrl}/subscriptions`;
  const payload: CreateSubscriptionPayload = {
    plan_variant_id: planVariant.id.toString(),
    setup_intent_id,
    discount_code,
  };
  if (addOn) {
    const { customCreditPackAmount, data: creditPack, isCustomCreditPack } = addOn;
    const credit_pack_variant: PurchaseCreditPackPayload | PurchaseCustomCreditPackPayload =
    isCustomCreditPack ?
      {
        is_custom: true,
        recurring_credits: customCreditPackAmount,
        payment_intent: payment_intent_id,
      } :
      {
        credit_pack_variant_id: creditPack.id.toString(),
        payment_intent: payment_intent_id,
      };
    payload.credit_pack_variant = credit_pack_variant;
  }
  try {
    const result = await axios.post(url, payload, { headers: { authorization: user.token }}) as AxiosResponse<GenericResponse<ConfirmSubscriptionOrderResponse>>;
    await subscribeHelpers.afterSubscribe(
      user,
      planVariant,
      result.data.data.subscription,
      dispatch,
      selectedLicenseId,
      currentPlan,
    );
    return result.data.data;
  } catch (e) {
    return e;
  }
}

const subscribeHelpers = {
  subscribe,
  fetchSubscriptionCheckoutServerSideProps,
  afterSubscribe,
}

export default subscribeHelpers;
