import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';
import getCookie from '../../utils/getCookie';
import { captchaTokenCookieName } from '../../constants/test';

const Captcha: React.FC<any> = (props) => {
  const {
    onPassed = () => {},
   } = props;

   if (typeof window !== 'undefined' && window.Cypress) {
     // work in test mode
     const token = getCookie(captchaTokenCookieName);
     if (token)
      onPassed(token);
     return (<div cy-test-id="captcha"></div>)
   }

  const onCaptchaPassed = useCallback((token: string) => onPassed(token), []);

  return (
    <div cy-test-id="captcha">
      <GoogleReCaptcha onVerify={onCaptchaPassed}/>
    </div>
  )
}

export default Captcha;
