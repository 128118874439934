import * as uiConstants from '../../constants/ui';
import isMobile from '../../hooks/isMobile';

const { NOTIFICATION_STYLE, NOTIFICATION_MOBILE_STYLE } = uiConstants;

interface WelcomeNotificationProps {
  userFirstName: string;
  isFirstTimeLogin: boolean;
}

const WelcomeNotification: React.FC<any> = (props: WelcomeNotificationProps) => {
  const { userFirstName, isFirstTimeLogin } = props;
  const welcomeMessage = isFirstTimeLogin ? 'Welcome' : 'Welcome back';
  const mobile = isMobile();

  return (
    <div
      className={`bg-a-dark-gray rounded-[5px] pl-6 overflow-hidden relative border border-a-light-gray`}
      style={mobile ? NOTIFICATION_MOBILE_STYLE : NOTIFICATION_STYLE}
    >
      <div className="absolute w-2 h-full top-0 left-0 bg-a-green" />
      <span
        className="block pt-6 overflow-hidden text-white"
        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      >
        {welcomeMessage}, <span className="text-a-blue font-medium">{userFirstName}!</span>
      </span>
    </div>
  );
};

export default WelcomeNotification;
