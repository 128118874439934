import React from 'react';
import Link from 'next/link';
import { connect } from 'react-redux';
import { AUTH_ACTIONS } from '../../constants/actions';
import Messages from './Messages';
import { TextInput, PasswordInput } from '../../modules/Inputs';
import { Button } from '../../modules/Buttons';
import { ApplicationState } from '../../types/state/storeTypes';
import {
  CheckoutSignupState,
  CheckoutSignupProps,
  SignupFormData
} from '../../types/pages/checkout/CheckoutTypes';
import { signIn } from 'next-auth/react';
import domainBlacklist from '../../staticData/DisposableEmailDomains';
import { addMessage } from '../../state/actions/messagesActions';
import AfterLogin from '../helpers/AfterLogin';

const { AUTH_SHOW_LOGIN, AUTH_HIDE_SIGN_UP, AUTH_PASSWORDLESS_REGISTER, AUTH_REGISTER_ERR } = AUTH_ACTIONS;

class SignupForm extends React.Component<CheckoutSignupProps, CheckoutSignupState> {
  constructor(props: CheckoutSignupProps) {
    super(props);
    this.state = {
      errors: [],
      passwordsMatch: false,
      passwordsLength: false,
      loading: false,
      loadingText: 'signing you up...',
      defaultFormClass: 'text-white mx-auto w-2/3 md:w-1/3 flex flex-col gap-6',
      defaultButtonClass:
        'uppercase bg-a-blue hover:bg-a-light-blue rounded-[5px] pt-4 pb-5 text-[18px] eurostile font-semibold',
      showAfterLogin: false
    };

    this.renderErrors = this.renderErrors.bind(this);
    this.checkReqs = this.checkReqs.bind(this);
    this.checkEntries = this.checkEntries.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private PASSWORD_MIN_LENGTH: number = 6;
  private passwordInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  handleSubmit(event: React.FormEvent): void {
    event.preventDefault();
    if (this.state.loading) return;
    this.props.paswordlessSignup
      ? this.signUpWithoutPassword(event)
      : this.signUpWithPassword(event);
  }

  signUpWithPassword(event: React.FormEvent): void {
    this.setState({ loading: true });
    const formData: FormData = new FormData(event.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const data: SignupFormData = {
      firstName: String(entries.firstName),
      lastName: String(entries.lastName),
      email: String(entries.email),
      password: String(entries.password),
      free_subscriber: true
    };

    const errors: string[] = this.checkEntries(data);
    if (errors.length > 0) {
      return this.setState({ errors, loading: false });
    }

    this.setState({ errors: [] });
    this.props.dispatch({
      type: AUTH_REGISTER_ERR,
      error: {},
    });

    this.props.dispatch({
      type: AUTH_ACTIONS.AUTH_REGISTER,
      sensitive: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        free_subscriber: data.free_subscriber
      },
      onSuccess: () => this.afterSignupCallback(data),
      onError: (err: any) => this.errorCallback(err)
    });
  }

  signUpWithoutPassword(event: React.FormEvent): void {
    this.setState({ loading: true });
    const formData: FormData = new FormData(event.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const password = Math.random().toString(36).slice(-8);
    const data: SignupFormData = {
      firstName: String(entries.firstName),
      lastName: String(entries.lastName),
      email: String(entries.email),
      password: password
    };

    const errors: string[] = this.checkEntries(data);
    if (errors.length > 0) {
      return this.setState({ errors, loading: false });
    }

    this.setState({ errors: [] });
    this.props.dispatch({
      type: AUTH_REGISTER_ERR,
      error: {},
    });

    this.props.dispatch({
      type: AUTH_PASSWORDLESS_REGISTER,
      sensitive: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password
      },
      onSuccess: () => this.afterSignupCallback(data),
      onError: (err: any) => this.errorCallback(err)
    });
  }

  afterSignupCallback = async (data: SignupFormData) => {
    this.setState({ loadingText: 'logging you in...' });
    await signIn('credentials', { email: data.email, password: data.password, redirect: false });
    this.setState({ showAfterLogin: true });
  };

  onAfterLoginSuccess = () => {
    this.props.dispatch({ type: AUTH_HIDE_SIGN_UP });
    this.setState({ loading: false, showAfterLogin: false });
    if (location.href.includes('/pricing')) location.href = '/subscribed-to-free-plan';
    if (!this.props.handleSuccess) return;
    this.props.handleSuccess();
  };

  errorCallback = (error: any) => {
    this.setState({ loading: false });
    this.props.dispatch(
      addMessage({
        type: 'error',
        body: error?.response?.data?.message
      })
    );
  };

  extractDomainFromEmail = (email) => {
    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = regex.exec(email);
    return match ? match[1] : null;
  };

  checkEntries(formData: SignupFormData): string[] {
    const { passwordsLength } = this.state;
    let errors: string[] = [];

    const domain = this.extractDomainFromEmail(formData.email.toLowerCase());
    if (domainBlacklist.includes(domain)) {
      errors.push(
        `Temporary email accounts are not allowed, please register using an appropriate email address.`
      );
    }

    if (!this.props.paswordlessSignup && !passwordsLength)
      errors.push('Passwords are not long enough');
    if (!formData.firstName) errors.push('First name is required');
    if (!formData.lastName) errors.push('Last name is required');
    if (!formData.email) errors.push('Email is required');
    return errors;
  }

  checkReqs(): void {
    const passwordOne: string = this.passwordInput.current!.value;
    const length: number = passwordOne.length;
    const passwordsLength = Boolean(length >= this.PASSWORD_MIN_LENGTH);

    this.setState({ passwordsLength });
  }

  renderErrors = (): JSX.Element => {
    let errors: string[] = this.state.errors;
    const { error } = this.props;
    const duplicateError: boolean = errors.includes(error);
    if (error && !duplicateError) errors.push(error);
    return (
      <Messages
        format="inline"
        messages={[{ body: errors.join('/ '), type: 'error' }]}
        onCloseMessage={() => {
          this.setState({ errors: [] });
          this.props.dispatch({
            type: AUTH_REGISTER_ERR,
            error: {},
          });
        }}
      />
    );
  };

  componentDidMount() {
    if (this.props.paswordlessSignup) return;
    this.passwordInput.current!.addEventListener('input', this.checkReqs);
  }

  componentWillUnmount() {
    if (this.props.paswordlessSignup) return;
    this.passwordInput.current!.removeEventListener('input', this.checkReqs);
  }

  render() {
    return (
      <form
        className={
          this.props.customFormClass ? this.props.customFormClass : this.state.defaultFormClass
        }
        onSubmit={this.handleSubmit}
        cy-test-id="signup-form">
        {!this.props.minimalView && (
          <div className="text-center font-semibold text-[28px]">Create Your Free Account</div>
        )}
        {this.renderErrors()}
        <div className="flex gap-6">
          <TextInput
            className="w-full"
            name="firstName"
            label="first name"
            placeholder="First Name"
            autoComplete="given-name"
          />
          <TextInput
            className="w-full"
            name="lastName"
            label="last name"
            placeholder="Last Name"
            autoComplete="family-name"
          />
        </div>
        <div className="flex gap-6 w-full">
          <TextInput
            className="w-full"
            name="email"
            label="email"
            placeholder="Email Address"
            autoComplete="email"
          />
        </div>
        {!this.props.paswordlessSignup && (
          <>
            <div className="w-full">
              <PasswordInput
                name="password"
                inputRef={this.passwordInput}
                label="password"
                placeholder="Password"
                autoComplete="new-password"
              />
            </div>
          </>
        )}

        <Button
          disabled={this.state.loading}
          disabledText={this.state.loadingText}
          type="submit"
          text={'Get Started'}
          className={
            this.props.customButtonClass
              ? this.props.customButtonClass
              : this.state.defaultButtonClass
          }
        />

        <p className="text-[15px] text-a-light-gray">
          By creating an account, you agree to the ActionVFX
          <Link className="text-a-blue" href="/terms-of-service">
            {' '}
            Terms of Service
          </Link>{' '}
          and
          <Link className="text-a-blue" href="/privacy-policy">
            {' '}
            Privacy Policy
          </Link>
          .
        </p>

        {!this.props.minimalView && (
          <div className="text-[15px] pt-2 text-a-light-gray">
            Already have an account? Please{' '}
            <Link
              className="text-a-blue"
              href="#"
              onClick={() => this.props.dispatch({ type: AUTH_SHOW_LOGIN })}>
              Sign In
            </Link>
          </div>
        )}

        {this.state.showAfterLogin && (
          <AfterLogin
            afterLoginTasksCompleted={() => this.onAfterLoginSuccess()}
            process="signup"
          />
        )}
      </form>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  error: state.auth.error ? state.auth.error.message : null
});

export default connect(mapStateToProps)(SignupForm);
