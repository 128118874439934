import { useDispatch } from 'react-redux';
import { useRef, useEffect } from 'react';
import { CollectSearchResultsPluginProps } from '../../types/components/user-interest-analytics/CollectSearchResultsPlugin';
import { ISearchResult, SearchResultType } from '../../types/api/UserInterestAnalyticsTypes';
import { Ref } from '../../types/api/BasicTypes';
import { ProductSearchResult } from '../../types/api/ProductsIndexTypes';

import { addResultToBuffer } from '../../state/actions/userInterestsAnalyticsActions';

const CollectSearchResultsPlugin: React.FC<CollectSearchResultsPluginProps> = (props: CollectSearchResultsPluginProps) => {
  // SendSearchAnalyticsPlugin and CollectSearchResultsPlugin modes of work should be equal to track search
  const { collection, category, product, libraryId, mode } = props;

  const dispatch = useDispatch();
  const elRef = useRef(null);
  const searchResultReported = useRef<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      const boundingRect = elRef.current?.getBoundingClientRect();
      if (!boundingRect) return;
      const isVisible = boundingRect.top >= 0 && boundingRect.top < window.innerHeight;
      if (!isVisible) return;
      handleElementInView();
    }

    // call to store results that are in view when loaded
    // give time for result div to set up using
    // setTimeout, the duct tape of JavaScript
    setTimeout(() => onScroll(), 10);

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, []);

  const getResultType = (): SearchResultType => {
    let ret: string;
    if (collection)
      ret = 'collection';
    if (category)
      ret = 'category';
    if (product)
      ret = 'product';
    return `${ret}-${libraryId}` as SearchResultType;
  }

  const handleElementInView = () => {
    if (searchResultReported.current) return;
    const result_type: SearchResultType = getResultType();
    let resultObject: Ref | ProductSearchResult;
    switch (result_type) {
      case 'collection-2d':
      case 'collection-3d':
      case 'collection-pfl':
        resultObject = collection;
        break;
      case 'category-2d':
      case 'category-3d':
      case 'category-pfl':
        resultObject = category;
        break;
      case 'product-2d':
      case 'product-3d':
      case 'product-pfl':
        resultObject = product;
        break;
      default:
        break;
    };
    if (!resultObject)
      return;
    const result_name = (resultObject as Ref).name || (resultObject as ProductSearchResult).value;
    const result_id = (resultObject as Ref).id?.toString() || (resultObject as ProductSearchResult).path;
    const result: ISearchResult = {
      result_type,
      result_id,
      result_name,
    };
    dispatch(addResultToBuffer(result, mode));
    searchResultReported.current = true;
  }

  return <div ref={elRef}></div>
}

export default CollectSearchResultsPlugin;
