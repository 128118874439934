import React from 'react';
import { SmallCheckmarkIcon } from '../../../modules/Icons';

export const CheckmarkButton: React.FC<any> = (props) => {
  const { checked, onClick } = props;

  return (
    <button
      cy-test-id="checkmark-button"
      onClick={onClick}
      className="flex-shrink-0 items-center justify-center pl-[2px] bg-white rounded-[2px] w-[16px] h-[16px] z-[99]">
      {checked && <SmallCheckmarkIcon />}
    </button>
  );
};
