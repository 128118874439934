import { CardSelectionProps, CardSelectionStateProps } from '../../types/components/subscription-upgrade-modal/CardSelection';
import { StripePaymentMethodsList } from '../../types/api/stripe/paymentMethodsList';
import { User } from '../../types/api/UsersTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import stripeHelpers from '../../helpers/stripe/helpers';

import { PaymentElement } from '@stripe/react-stripe-js';

const CardSelection: React.FC<CardSelectionProps> = ({
  user,
  onCardsLoaded,
}) => {
  const { getCardsFor } = stripeHelpers;

  const [savedCards, setSavedCards] = useState<StripePaymentMethodsList>();
  const [error, setError] = useState<string>();

  const loadSavedCards = async () => {
    try {
      const cards = await getCardsFor(user) as StripePaymentMethodsList;
      setSavedCards(cards);
      onCardsLoaded(cards);
    } catch (e) {
      setError(e.message || e.error?.message || 'An error has occured');
    }
  }

  useEffect(() => {
    loadSavedCards();
  }, []);

  const renderError = (): React.ReactElement => {
    return error && <p className="bg-red-900 text-white p-3 text-[12px]" cy-test-id="error-block">{error}</p>
  }

  const renderNewCardInput = (): React.ReactElement => {
    return !!savedCards && !savedCards.data.length &&
    <div cy-test-id="add-card-form" className="w-full p-6 bg-a-dark-gray rounded-[5px]">
      <PaymentElement />
    </div>
  }

  return (
    <div className="w-full" cy-test-id="card-selection">
      {renderNewCardInput()}
      {renderError()}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): CardSelectionStateProps => ({
  user: state.auth.user as User,
});

export default connect(mapStateToProps)(CardSelection);
