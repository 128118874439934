import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { addNotification } from '../../state/actions/notificationsActions';

import SuccessfulPurchaseNotification from '../shared/SuccessfulPurchaseNotification';

const UrlParamsHandler: React.FC<{}> = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const showCreditPackPurchaseNotification = (creditsAmount: number) => {
    router.push('/', undefined, { shallow: true });
    dispatch(
      addNotification({
        body: <SuccessfulPurchaseNotification purchaseType={'creditPack'} creditsAmount={creditsAmount} />,
        customUi: true
      })
    );
  }

  const handleUrlParams = () => {
    const from_paypal = router.query?.from_paypal;
    const purchased_credits = router.query?.purchased_credits;
    if (from_paypal === 'true' && !!purchased_credits)
      showCreditPackPurchaseNotification(parseInt(purchased_credits as string));
  }

  useEffect(handleUrlParams, []);

  return <div className="fixed w-0 h-0" cy-test-id="url-params-handler"></div>;
}

export default UrlParamsHandler;
