import React, { useEffect } from 'react';
import { MessagesProps, MessageProps } from '../../types/components/shared/MessagesTypes';
import { messagePresets } from '../../staticData/Messages';
import { ExitIcon } from '../../modules/Icons';
import { clearMessages } from '../../state/actions/messagesActions';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';

export const inlineContainerClass = 'messages-container';
export const defaultContainerClass = 'messages-container fixed z-[999] w-full top-[70px]';
export const typeClasses = {
  alert: 'bg-orange-900',
  info: 'bg-blue-1000',
  warning: 'bg-orange-900',
  error: 'bg-red-900',
  success: 'bg-green-1000'
};
export const genericMessageClasses =
  ' w-full p-4 text-center text-[14px] font-normal text-white bg-opacity-80 relative flex';

const Messages: React.FC<MessagesProps> = (props) => {
  const router = useRouter();
  const { messages, format, onCloseMessage } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      props.dispatch(clearMessages());
    }, 12000);
    return () => clearTimeout(timer);
  }, [props.messages]);

  useEffect(() => {
    props.dispatch(clearMessages());
  }, [router.asPath]);

  if (messages == undefined || messages.length <= 0) return null;

  const containerClasses = format == 'inline' ? inlineContainerClass : defaultContainerClass;
  // For preset messages we reference staticData by key and use it instead.
  const getMessage = (message) => {
    if (message.body !== undefined && message.body.length > 0) {
      return message;
    }
    return messagePresets[message.message_key];
  };

  return (
    <div className={containerClasses} data-testid="messagesContainer">
      {messages.map((m, i) => (
        <Message
          key={i}
          message={getMessage(m)}
          dispatch={props.dispatch}
          onCloseMessage={onCloseMessage}
        />
      ))}
    </div>
  );
};

const Message: React.FC<MessageProps> = (props) => {
  const { message, onCloseMessage } = props;
  if (message == undefined || message.body.length <= 0) return null;

  const closeMessage = () => {
    onCloseMessage ? onCloseMessage() : props.dispatch(clearMessages());
  };

  return (
    <div cy-test-id="message" className={typeClasses[message.type] + genericMessageClasses}>
      {message.body}
      <ExitIcon onClick={closeMessage} className="w-[20px] ml-auto -mr-1.5 -mt-1.5 stroke-white cursor-pointer" />
    </div>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Messages);
