import { LegacyToModernUpgradeWarningProps, LegacyToModernUpgradeWarningStateProps } from '../../types/components/subscription-upgrade-modal/LegacyToModernUpgradeWarning';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import { connect } from 'react-redux';
import subscriptionHelpers from '../../helpers/subscriptionHelpers';

const LegacyToModernUpgradeWarning: React.FC<LegacyToModernUpgradeWarningProps> =({
  subscription,
}) => {
  const { isCreditBasedLegacy } = subscriptionHelpers;
  if (!subscription || !isCreditBasedLegacy(subscription)) return <></>;
  return (
    <div className="flex flex-col gap-3 w-full rounded-[5px] p-4 bg-a-dark-gray border-red-100 border-[1px]" cy-test-id="legacy-to-modern-upgrade-warning">
      <span className="inter text-16 leading-28 font-semibold text-white uppercase">Warning: Legacy Credit Loss Upon Upgrade</span>
      <p className="text-12 leading-22 inter font-normal text-a-gray">
        Upgrading your subscription will result in the permanent loss of <span className="text-white font-semibold">all</span> unused legacy credits associated with your current plan.
        This change cannot be reversed.
        If you wish to retain your legacy credits, please ensure you have used them before proceeding with the upgrade.
      </p>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): LegacyToModernUpgradeWarningStateProps => ({
  subscription: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(LegacyToModernUpgradeWarning);
