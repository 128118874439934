import { StripeCustomerData } from '../types/api/stripe/customer';
import { StripePaymentMethodData } from '../types/api/stripe/paymentMethod';
import Stripe from 'stripe';

export const getFirstPaymentMethodByCustomer = async (stripe: Stripe, customer: StripeCustomerData): Promise<StripePaymentMethodData> => {
  const paymentMethods = await stripe.customers.listPaymentMethods(customer.id, { type: 'card' });
  return paymentMethods.data[0] as unknown as StripePaymentMethodData;
};

const stripeHelpers = {
  getFirstPaymentMethodByCustomer,
};

export default stripeHelpers;
