import { PaymentBlockProps } from '../../../types/components/credits/payment-block/PaymentBlock';

import CreditPackPayment from './CreditPackPayment';
import CreditPlanPayment from './CreditPlanPayment';

const PaymentBlock: React.FC<PaymentBlockProps> = (props) => {
  const { selectedOption } = props;

  return (
    <div cy-test-id="payment-block">
      {selectedOption?.type === 'creditPack' && <CreditPackPayment {...props} />}
      {selectedOption?.type === 'creditPlan' && <CreditPlanPayment {...props} />}
    </div>
  )
};

export default PaymentBlock;
