import { CreditPlanPaymentProps } from '../../../types/components/credits/payment-block/CreditPlanPayment';

import CardSelectionAndPaymentBlock from '../../checkout/CardSelectionAndPaymentBlock';

const CreditPlanPayment: React.FC<CreditPlanPaymentProps> = ({
  selectedOption,
  onPurchaseCompleted,
  selectedOptionValid,
}) => {
  const submitDisabled = (): boolean => {
    return !selectedOption || !selectedOptionValid;
  };

  return (
    <div cy-test-id="credit-plan-payment">
      <CardSelectionAndPaymentBlock
        disableSubmit={submitDisabled()}
        purchasedCreditOption={selectedOption}
        onPurchaseCompleted={onPurchaseCompleted}
        doNotRedirectAfterUpgrade={true}
      />
    </div>
  )
}

export default CreditPlanPayment;
