import { PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { PLAN_VARIANTS_CACHE_ACTIONS } from '../../constants/actions';
const { SET_PLAN_VARIANTS, CLEAR_PLAN_VARIANTS } = PLAN_VARIANTS_CACHE_ACTIONS;

export const setPlanVariants = (
  planVariants: Array<PlanVariantResponse>,
  licenseId: LicenseId,
) => ({
  type: SET_PLAN_VARIANTS,
  planVariants,
  licenseId,
});

export const clearPlanVariants = () => ({
  type: CLEAR_PLAN_VARIANTS,
});
