import React, { useState, useEffect } from 'react';
import { licenseByLicenseId } from '../../helpers/layout/shoppingCartHelpers';
import TeamOrStudioAssistDialog from '../shared/TeamOrStudioAssistDialog';
import Link from 'next/link';
import { LicenseDetail } from '../../types/api/LicenseTypesTypes';
import getLicenseTypes from '../../hooks/getLicenseTypes';

export default function LicenseConfirmation(props) {
  const { selectedLicenseId, setLicenseConfirmed } = props;

  const [license, setLicense] = useState<LicenseDetail>();

  const { licenseTypes } = getLicenseTypes();

  useEffect(() => {
    if (!licenseTypes) return;
    const selectedLicenseData = licenseByLicenseId(selectedLicenseId, licenseTypes);
    setLicense(selectedLicenseData.license);
  }, [licenseTypes]);

  const [teamOrStudioAssistDialogOpen, setTeamOrStudioAssistDialogOpen] = useState(null);
  function openTeamOrStudioAssistDialog() {
    setTeamOrStudioAssistDialogOpen(true);
  }

  return (
    <>
      {
        license &&
        <div className="flex gap-2 items-start" cy-test-id="license-confirmation">
          <input
            id="licenseConfirm"
            className=""
            type="checkbox"
            onChange={(e) => {
              setLicenseConfirmed(e.target.checked);
            }}
          />
          {license.id == 1 && (
            <label htmlFor="licenseConfirm" className="text-14 -mt-1">
              I confirm that I, an individual, will be the sole user of these assets and they will
              never be used by a
              <Link className="text-a-blue" href="#" onClick={() => openTeamOrStudioAssistDialog()}>
                {' '}
                team or studio
              </Link>
              . Violating this condition will void my license and may result in legal action.
            </label>
          )}
          {license.id == 2 && (
            <label htmlFor="licenseConfirm" className="text-14 -mt-1">
              I confirm that my company's revenue is under $3M per year. I agree that falsifying this
              information will void my license.
            </label>
          )}
          {license.id > 2 && (
            <label htmlFor="licenseConfirm" className="text-14 -mt-1 text-justify">
              I confirm that my company’s revenue is between {license.range} per year. I agree that
              falsifying this information will void my license.
            </label>
          )}
        </div>
      }
      <TeamOrStudioAssistDialog
        dialogIsOpen={teamOrStudioAssistDialogOpen}
        setDialogIsOpen={setTeamOrStudioAssistDialogOpen}
      ></TeamOrStudioAssistDialog>
    </>
  );
}
