import React from 'react';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { Warning, CheckmarkIcon } from '../../modules/Icons';
import { licenseErrorCards } from './LicenseConfigurations';
import { LicenseErrorProps } from '../../types/components/license-profile/LicenseError';
import { useRouter } from 'next/router';

const LicenseError: React.FC<LicenseErrorProps> = ({
  onBack,
  onClose,
  currentLicense,
  suggestedLicense
}) => {
  const router = useRouter();

  const handleUpgradeRedirect = () => {
    router.push('/pricing');
    onClose();
  }

  const getCurrentLicense = () => {

    if (!currentLicense.title) {
      return licenseErrorCards.Individual;
    }
    const key = Object.keys(licenseErrorCards).find(
      (key) => key.toLowerCase() === currentLicense.title.toLowerCase()
    );
    return key
      ? licenseErrorCards[key as keyof typeof licenseErrorCards]
      : licenseErrorCards.Individual;
  };

  const getSuggestedLicense = () => {
    if (!suggestedLicense.title) {
      return licenseErrorCards.Startup;
    }
    const key = Object.keys(licenseErrorCards).find(
      (key) => key.toLowerCase() === suggestedLicense.title.toLowerCase()
    );
    return key
      ? licenseErrorCards[key as keyof typeof licenseErrorCards]
      : licenseErrorCards.Startup; // Fallback to Startup if not found
  };

  const currentLicenseData = getCurrentLicense();
  const suggestedLicenseData = getSuggestedLicense();

  const buttonText = () =>
    currentLicenseData.id < suggestedLicenseData.id ? 'upgrade plan' : 'downgrade plan';

  return (
    <div cy-test-id="license-error" className="flex flex-col gap-4 max-w-[560px] mx-auto">
      <div className="my-[30px] lg:my-[46px] flex flex-col gap-[24px]">
        <p className="text-[18px] my-[10px] lg:my-0 lg:text-[21px] text-white">
          Seems like your current subscription plan doesn't cover your requirements.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-[16px] justify-center mb-[30px] lg:mb-[46px]">
        <div className="bg-gray-900 px-[32px] py-[20px] rounded-[5px] md:w-[292px] h-[176px]">
          <span className="flex items-center gap-2 text-gray-600 uppercase text-[14px]">
            <Warning /> Current Plan
          </span>
          <div className="capitalize text-[18px] text-white font-semibold my-[10px] lg:my-[8px]">
            {currentLicenseData.title}
          </div>

          <ul className="list-disc pl-5 text-[8px] lg:text-[12px] text-white">
            {currentLicenseData.descriptionList.map((item, index) => (
              <li key={index} className="mb-2">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-gray-900 px-[32px] py-[20px] rounded-[5px] md:w-[292px] h-[176px]">
          <span className="flex items-center gap-2 text-gray-600 uppercase text-[14px]">
            <CheckmarkIcon /> Suggested plan
          </span>

          <div className="capitalize text-[18px] text-white font-semibold my-[10px] lg:my-[8px]">
            {suggestedLicenseData.title}
          </div>

          <ul className="list-disc pl-5 text-[8px] lg:text-[12px] text-white">
            {suggestedLicenseData.descriptionList.map((item, index) => (
              <li key={index} className="mb-2">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="text-center text-gray-500">
        Have any questions? Contact{' '}
        <a className="text-a-blue" href="mailto:support@actionvfx.com">
          support@actionvfx.com
        </a>
      </div>

      <div className="flex gap-4 justify-end">
        <MainLinkButton
          className="!w-[272px] bg-white bg-opacity-[10%] hover:bg-white hover:bg-opacity-[20%] text-white"
          onClick={onBack}>
          Back
        </MainLinkButton>
        <MainLinkButton
          variant="blue"
          className="!w-[292px] hover:bg-a-green !text-[12px] md:!text-[17px] p-2 md:p-0"
          onClick={handleUpgradeRedirect}>
          {buttonText()}
        </MainLinkButton>
      </div>
    </div>
  );
};

export default LicenseError;
