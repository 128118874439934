import { FetchHookResponse } from '../types/hooks/hooksTypes';
import { PlanVariantResponse } from '../types/api/SubscriptionTypes';
import { ApplicationState } from '../types/state/storeTypes';
import { LicenseId } from '../types/api/LicenseTypesTypes';

import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setPlanVariants } from '../state/actions/planVariantsCacheActions';

import { ENV } from '../constants/environments';

export default function getPlanVariants(licenseId: LicenseId): FetchHookResponse<Array<PlanVariantResponse>> {
  let planVariantsUrl = `/plan_variants?license_id=${licenseId}`;
  const baseUrl = ENV.api.baseURL;

  const dispatch = useDispatch();
  const state: ApplicationState = useSelector((state) => state) as ApplicationState;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!!state.planVariantsCache[licenseId]) return;

    const getPlanVariants = async () => {
      setLoading(true);
      try {
        const planVariantsResult: AxiosResponse<Array<PlanVariantResponse>> = await axios.get(
          `${baseUrl}${planVariantsUrl}`, {
            headers: { authorization: state?.auth?.user?.token }
          }
        );
        const planVariants = planVariantsResult.data;
        dispatch(setPlanVariants(planVariants, licenseId));
        setLoading(false);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    }

    getPlanVariants();
  }, []);

  return { loading, error, data: state.planVariantsCache[licenseId] };
}
