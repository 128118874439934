import React from 'react';
import { Button } from './Buttons';
import {
  BasicInputProps,
  IconTextInputProps,
  ButtonTextInputProps,
  SelectInputProps,
  CheckboxInputProps,
  RadioGroupProps,
  SwitchOptionProps,
  SwitchInputProps
} from '../types/modules/InputsTypes';

export const BasicInput: React.FC<BasicInputProps> = (props) => {
  const {
    onChange,
    defaultValue,
    value,
    autoComplete,
    className,
    type,
    onFocus,
    required,
    pattern
  } = props;
  const variantClass: string = className ? className : '';
  const stateProps = {};
  if (onChange) Object.assign(stateProps, { onChange: onChange });
  if (defaultValue) Object.assign(stateProps, { defaultValue: defaultValue });
  if (value) Object.assign(stateProps, { value: value });
  if (autoComplete) Object.assign(stateProps, { autoComplete });
  return (
    <div className={`input-container ${variantClass}`}>
      <input
        cy-test-id="form-input"
        className="basicInput w-full h-[40px] py-1 px-4 border bg-white bg-opacity-[5%] border-[#4D5457] rounded-[5px] text-white text-[16px] focus:outline-0 focus:border-1 focus:border-[#00A1E0]"
        type={type || 'text'}
        name={props.name || ''}
        placeholder={props.placeholder || ''}
        autoFocus={props.autofocus || false}
        autoComplete={props.autoComplete}
        onFocus={onFocus}
        required={required || false}
        {...(!!pattern && { pattern: pattern })}
        {...stateProps}
      />
    </div>
  );
};

export const TextInput: React.FC<BasicInputProps> = (props) => {
  const { onChange, defaultValue, value, autoComplete, className } = props;
  const variantClass: string = className ? className : '';
  const stateProps = {};
  if (onChange) Object.assign(stateProps, { onChange: onChange });
  if (defaultValue) Object.assign(stateProps, { defaultValue: defaultValue });
  if (value) Object.assign(stateProps, { value: value });
  if (autoComplete) Object.assign(stateProps, { autoComplete });
  return (
    <div className={`input-container ${variantClass}`}>
      <input
        cy-test-id="text-input"
        className="w-full py-3 px-4 border bg-[#252B2D] border-[#4D5457] rounded-[5px] text-white"
        type="text"
        name={props.name || ''}
        placeholder={props.placeholder || ''}
        autoFocus={props.autofocus || false}
        autoComplete={props.autoComplete || 'false'}
        {...stateProps}
      />
    </div>
  );
};

export const IconTextInput: React.FC<IconTextInputProps> = (props) => {
  const inputType: string = props.password ? 'password' : 'text';
  const variantClass: string = props.variant ? props.variant : '';

  return (
    <div className={`input-container ${variantClass}`}>
      <input
        className="w-full py-3 px-4 border bg-[#252B2D] border-[#4D5457] rounded-[5px] text-white"
        type={inputType}
        name={props.name}
        placeholder={props.placeholder}
        autoFocus={props.autofocus}
      />
      <span data-for={props.label} data-tip className="icon" data-testid="input-icon">
        {props.icon}
      </span>
    </div>
  );
};

export const ButtonTextInput: React.FC<ButtonTextInputProps> = (props) => {
  const {
    initialValue,
    placeholder,
    height,
    autofocus,
    buttonText,
    buttonVariant,
    error,
    testid,
    variant
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const value = initialValue ? initialValue : '';
  const variantClass: string = variant ? variant : '';

  function submitValue(): void {
    const inputVal: string = inputRef.current.value;
    props.onClick(inputVal);
  }

  function renderErrors(): React.ReactNode {
    if (!error) return null;
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div
      className={`input-group-container ${variantClass}`}
      style={{ height: height || '40px' }}
      data-testid={testid || ''}>
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        autoFocus={autofocus}
        defaultValue={value}
      />
      <div className="btn-wrapper" onClick={submitValue}>
        <Button variant={buttonVariant} text={buttonText} />
      </div>
      {renderErrors()}
    </div>
  );
};

export const PasswordInput: React.FC<BasicInputProps> = (props) => {
  let stateProps = {};
  if (props.autoComplete) Object.assign(stateProps, { autoComplete: props.autoComplete });
  const variantClass: string = props.variant ? props.variant : '';
  return (
    <div className={`input-container ${variantClass}`}>
      <input
        cy-test-id="password-input"
        className="w-full py-3 px-4 border bg-[#252B2D] border-[#4D5457] rounded-[5px] text-white disabled:opacity-[0.75]"
        name={props.name}
        ref={props.inputRef}
        type="password"
        placeholder={props.placeholder}
        autoFocus={props.autofocus}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        {...stateProps}
      />
    </div>
  );
};

export const NumberInput: React.FC<BasicInputProps> = (props) => {
  const variantClass: string = props.variant ? props.variant : '';
  return (
    <div className={`input-container ${variantClass}`}>
      <span>{props.label}</span>
      <input
        type="number"
        name={props.name || ''}
        placeholder={props.placeholder}
        style={{ height: props.height || '30px' }}
      />
    </div>
  );
};

export const SelectInput: React.FC<SelectInputProps> = (props) => {
  const variantClass: string = props.variant ? props.variant : '';
  let selectedValue: string;

  const selectOptions: React.ReactNode[] = props.options.map((opt) => {
    if (opt.selected) selectedValue = opt.value;
    return (
      <option key={opt.value} value={opt.value}>
        {opt.text}
      </option>
    );
  });

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    if (!props.onChange) return;
    const value: string = event.target.value;
    props.onChange(value, event.target);
  }

  return (
    <div className={`input-container ${variantClass}`}>
      <span>{props.label}</span>
      <select
        style={{ height: props.height || '30px' }}
        name={props.name}
        onChange={handleChange}
        defaultValue={selectedValue}>
        {selectOptions}
      </select>
    </div>
  );
};

export const CheckboxInput: React.FC<CheckboxInputProps> = (props) => {
  const [checked, setChecked] = React.useState(false);
  const variantClass: string = props.variant ? props.variant : '';

  function handleClick(): void {
    setChecked(!checked);
  }

  return (
    <div className={`input-container-checkbox ${variantClass}`}>
      <input type="checkbox" name={props.name} value={1} checked={checked} onChange={handleClick} />
      <span>{props.label}</span>
    </div>
  );
};

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  let radioOptions: React.ReactNode[] = [];
  props.options.forEach((opt, index) => {
    radioOptions.push(
      <div key={opt.value} className="input-container-radio">
        <input
          type="radio"
          value={opt.value}
          name={props.name}
          data-testid={`radio-input-${index}`}
        />
        <span>{opt.text}</span>
      </div>
    );
  });
  return <>{radioOptions}</>;
};

export const SwitchInput: React.FC<SwitchInputProps> = (props) => {
  const { items, name, width, testid, variant, disabled, onChange } = props;
  function handleChecked(e) {
    if (disabled) return null;

    if (e.target.checked) {
      onChange(items.checkedValue.value);
    } else {
      onChange(items.unCheckedValue.value);
    }
  }

  return (
    <div className="input-container-switch switch" data-testid={testid}>
      <input
        className="switch-input"
        id={name}
        type="checkbox"
        disabled={disabled}
        defaultChecked={items.checked}
        name={name}
        onChange={handleChecked}
        data-testid="switchInput"
      />
      <label
        data-testid="input-label"
        className={`switch-paddle ${variant}`}
        htmlFor={name}
        style={{ width: width || '150px' }}>
        <SwitchOption active={true} text={items.checkedValue.text} />
        <SwitchOption active={false} text={items.unCheckedValue.text} />
      </label>
    </div>
  );
};

const SwitchOption: React.FC<SwitchOptionProps> = (props) => {
  return (
    <span className={props.active ? 'switch-active' : 'switch-inactive'} aria-hidden="true">
      {props.text}
    </span>
  );
};
