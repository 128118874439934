import {
  CTAButtonProps,
  CTAButtonStateProps,
} from '../../types/components/nav/CTAButton';
import { ApplicationState } from '../../types/state/storeTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';

import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { User } from '../../types/api/UsersTypes';

const CTAButton: React.FC<CTAButtonProps> = ({
  authenticated,
  subscription,
  user
}) => {
  if (authenticated === undefined || !!authenticated && !!subscription) return <></>;
  const router = useRouter();

  const redirect = () => router.push('/pricing');

  const ctaText = () => {
    if (subscription?.active || user?.free_subscriber) return 'upgrade plan';
    return 'start free now';
  };

  return (
    <button
      cy-test-id="nav-cta-button"
      onClick={redirect}
      className="max-md:hidden min-w-fit uppercase px-3 2xl:px-6 h-[40px] bg-a-blue text-white rounded-[5px] eurostile text-12 2xl:text-17 leading-24 font-bold whitespace-nowrap"
    >
      {ctaText()}
    </button>
  );
};

const mapStateToProps = (state: ApplicationState): CTAButtonStateProps => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user as User,
  subscription: state.auth.subscription as SubscriptionDetail
});

export default connect(mapStateToProps)(CTAButton);
