import Link from 'next/link';

export const MainLinkButton: React.FC<any> = (props) => {
  const { href, children, className, variant, inline, target, onClick, disabled, size, testId } =
    props;

  let colorClasses = '';
  let widthClasses = '';
  let inlineClasses = '';

  switch (size) {
    case 'large':
      widthClasses = 'w-[330px] h-[56px]';
      break;
    case 'medium':
      widthClasses = 'w-[300px] h-[48px]';
      break;
    case 'auto':
      widthClasses = 'w-auto';
      break;
    case 'full':
      widthClasses = 'w-full';
      break;
    default:
      widthClasses = 'w-[215px] h-[56px]';
      break;
  }
  switch (widthClasses) {
    case 'w-[330px] h-[56px]':
      inlineClasses = '';
      break;
    case 'w-[300px] h-[48px]':
      inlineClasses = '';
      break;
    case 'w-auto':
      inlineClasses = 'inline-block';
      break;
    case 'w-full':
      inlineClasses = 'block';
      break;
    default:
      inlineClasses = '';
      break;
  }

  switch (variant) {
    case 'outlined':
      colorClasses = 'bg-[#1F3D48B2] hover:bg-[#114C63B2] text-a-blue hover:text-[#F5F5F5]';
      break;
    case 'green':
      colorClasses = 'bg-[#00B74F] hover:bg-[#00DA5E] text-[#F5F5F5]';
      break;
    case 'blue':
      colorClasses = 'bg-a-blue hover:bg-[#24C1FF] text-white';
      break;
    case 'outlined medium':
      colorClasses = 'bg-[#1F3D48B2] hover:bg-[#114C63B2] text-a-blue hover:text-[#F5F5F5]';
      break;
    case 'disabled':
      colorClasses = 'bg-[#FFFFFF1A] text-a-light-gray cursor-default';
      break;
    default:
      colorClasses = 'bg-[rgba(31,61,72,0.7)] hover:bg-[rgba(17,76,99,0.7)] text-[#24C1FF]';
      break;
  }

  return (
    <>
      {href && href.length > 0 && (
        <Link
          href={href}
          target={target || '_self'}
          className={
            colorClasses +
            ` ${widthClasses} ${inlineClasses} border-0 uppercase font-[Eurostile] text-[17px] font-semibold tracking-wide rounded-[5px] transition-colors duration-100 ` +
            className +
            ' flex justify-center items-center'
          }
          // className={
          //   colorClasses +
          //   ` ${inlineClasses} px-8 mt-auto border-0 uppercase text-center font-[Eurostile] pt-[10px] pb-[13px] text-[17px] font-semibold tracking-[2%] rounded-[5px] transition-colors duration-100 ` +
          //   className
          // }
        >
          {children}
        </Link>
      )}

      {!href && (
        <button
          onClick={onClick}
          className={
            colorClasses +
            ` ${widthClasses} ${inlineClasses} disabled:opacity-75 border-0 uppercase font-[Eurostile] tracking-wide text-[17px] font-semibold rounded-[5px] transition-colors duration-100 ` +
            className +
            ' flex justify-center items-center'
          }
          disabled={disabled || false}
          cy-test-id={testId || 'main-link-button'}>
          {children}
        </button>
      )}
    </>
  );
};
