import { ENV } from '../constants/environments';
import { User } from '../types/api/UsersTypes';
import axios from 'axios';

const apiUrl: string = ENV.api.baseURL;

export const updateLicenseProfile = async (user: User, profileData: any): Promise<void> => {
  try {
    const result = await axios.patch(`${apiUrl}/licensee_profiles/2`, profileData, {
      headers: {
        Authorization: `${user.token}`
      }
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createLicenseProfile = async (user: User, profileData: any): Promise<void> => {
  try {
    const result = await axios.post(`${apiUrl}/licensee_profiles/`, profileData, {
      headers: {
        Authorization: `${user.token}`
      }
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
