import React, { useEffect, useState } from 'react';
import { Loader } from '../shared/Loader';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { ENV } from '../../constants/environments';
import { useSearchParams } from 'next/navigation';
import { SmallExitIcon, TagIcon } from '../../modules/Icons';

const DiscountCode: React.FC<any> = (props) => {
  const { coupon, setCoupon } = props;

  const dispatch = useDispatch();
  const searchParams = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [couponError, setCouponError] = useState(null);
  const urlDiscount = searchParams?.get('discount_code');

  useEffect(() => {
    if (urlDiscount != undefined && urlDiscount?.length > 0) {
      setCode(urlDiscount);
      checkDiscount(urlDiscount);
    }
  }, [urlDiscount]);

  useEffect(() => {
    const cachedDiscount = localStorage.getItem('actionvfx_subscription_discount');
    const cachedCoupon = JSON.parse(cachedDiscount);
    if (cachedCoupon?.code) {
      checkDiscount(cachedCoupon?.code);
    }
  }, []);

  const checkDiscount = (discount = null): boolean => {
    if (loading) return false;
    setLoading(true);
    const apiUrl: string = `${ENV.api.baseURL}`;
    const discountCode = discount || code; // This is because setCode isn't always synchronous.

    dispatch({
      type: 'CHECK_SUBSCRIPTION_DISCOUNT',
      api: {
        method: 'GET',
        url: `${apiUrl}/subscriptions/check_discount`,
        params: {
          discount_code: discountCode
        },
        onError: (res) => {
          setLoading(false);
          localStorage.removeItem('actionvfx_subscription_discount');
          if (res.response.status == '503') {
            setCouponError('Too many attempts.')
            return;
          }
          setCouponError(res.response?.data?.message); 
        },
        onSuccess: (res: AxiosResponse) => {
          setLoading(false);
          setCoupon(res?.data?.discount);
          localStorage.setItem('actionvfx_subscription_discount', JSON.stringify(res?.data?.discount));
          // window.location.reload();
        }
      }
    });
    setLoading(false);
  };

  const removeDiscount = (): boolean => {
    if (loading) return false;
    setLoading(false);
    localStorage.removeItem('actionvfx_subscription_discount');
    window.location.reload();
  };

  return (
    <fieldset>
      {loading && <Loader />}

      {coupon && coupon.percent_off && (
        <div className="flex p-2 gap-2 bg-blue-1000 rounded-[5px] mt-6 text-white">
          <TagIcon className="w-[16px] fill-[#fff]"/>
          <p>{coupon.code}</p>

          <div className="ml-auto text-right">
            <button
              onClick={() => {
                removeDiscount();
              }}
              className="">
              <SmallExitIcon className="" />
            </button>
          </div>
        </div>
      )}

      {coupon?.percent_off == undefined && (
        <div>
          <div className="border border-gray-700 text-white flex rounded-[5px] pr-3 mt-6">
            <input
              type="text"
              name="code"
              placeholder="Promo Code"
              className="bg-transparent border-none text-white p-3 text-[14px] outline-none"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <button
              onClick={() => checkDiscount()}
              className="uppercase font-bold ml-auto text-[12px] outline-none">
              Apply
            </button>
          </div>
        </div>
      )}

      {couponError && (
        <div className="flex p-2 gap-2 bg-red-800 rounded-[5px] mt-6 text-white">
          <TagIcon className="w-[16px] fill-[#fff]"/>
          <p className="text-[14px]">{couponError}</p>

          <div className="ml-auto text-right">
            <button
              onClick={() => {
                setCouponError(null);
              }}
              className="">
              <SmallExitIcon className="" />
            </button>
          </div>
        </div>
      )}
    </fieldset>
  );
};

export default DiscountCode;
