import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { CREDIT_PACKS_CACHE_ACTIONS } from '../../constants/actions';
const { SET_CREDIT_PACKS } = CREDIT_PACKS_CACHE_ACTIONS;

export const setCreditPacks = (creditPacks: Array<CreditPackResponse>, license_id: LicenseId) => ({
  type: SET_CREDIT_PACKS,
  creditPacks,
  license_id,
});
