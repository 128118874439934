import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { connect, useDispatch } from 'react-redux';
import { getSession } from 'next-auth/react';

import { createSearchEvent, storeSearchResults, touchCookie } from '../../helpers/analytics/userInterestAnalytics';
import { clearSearch, emptyBuffer } from '../../state/actions/userInterestsAnalyticsActions';
import deepCopy from '../../utils/deepCopy';

import { User } from '../../types/api/UsersTypes';
import { SendSearchAnalyticsPluginProps } from '../../types/components/user-interest-analytics/SendSearchAnalyticsPlugin';
import { ISearchEventResponse, ISearchResult } from '../../types/api/UserInterestAnalyticsTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import { ANALYTICS_SEARCH_RESULTS_BUFFER_THRESHOLD } from '../../constants/utils';
import { ENV } from '../../constants/environments';

const SendSearchAnalyticsPlugin: React.FC<SendSearchAnalyticsPluginProps> = (props: SendSearchAnalyticsPluginProps) => {
  if (ENV.appEnv !== 'production') return <></>;

  // SendSearchAnalyticsPlugin and CollectSearchResultsPlugin modes of work should be equal to track search
  const { search_type, analyticsState, mode, searchTerm } = props;

  const getBuffer = (): Array<ISearchResult> => {
    switch (mode) {
      case 'productsPageSearch':
        return analyticsState?.products_page_results_buffer;
      case 'productsPageDropdownSearch':
        return analyticsState?.products_page_dropdown_results_buffer;
      case 'topNavSearch':
        return analyticsState?.top_nav_results_buffer;
      case 'homepageDropdownSearch':
        return analyticsState?.homepage_results_dropdown_buffer;
      default:
        return [];
    };
  };

  const getSearchTerm = (): string => {
    switch (mode) {
      case 'productsPageSearch':
        return router?.query?.search as string || '';
      default:
        return searchTerm;
    };
  }

  const router = useRouter();
  const dispatch = useDispatch();

  const [searchId, setSearchIdState] = useState<string>('');
  const searchIdRef = useRef<string>(searchId);
  const analyticsBufferRef = useRef<Array<ISearchResult>>(getBuffer());

  const registerNewSearch = async () => {
    const search_term: string = getSearchTerm();
    if (!search_term || searchId) return;
    const sessionData = await getSession();
    const user: User | null = sessionData?.user as User || null;
    try {
      const result: ISearchEventResponse = await createSearchEvent(search_type, search_term, user);
      const _searchId = result.data.search_id;
      setSearchIdState(_searchId);
      searchIdRef.current = _searchId;
    }
    catch { }
  }

  const sendResultsDetails = async () => {
    if (!searchId) return;
    const payload = getBuffer();
    if (!payload?.length) return;
    dispatch(emptyBuffer(mode));
    try {
      await storeSearchResults(searchId, payload);
    }
    catch { }
  }

  const sendRemainingDetails = async (_searchId: string) => {
    if (!_searchId || !analyticsBufferRef?.current?.length) return;
    const payload = deepCopy(analyticsBufferRef.current);
    analyticsBufferRef.current = null;
    await storeSearchResults(_searchId, payload);
  }

  useEffect(() => {
    if (mode !== 'productsPageSearch') return;
    analyticsBufferRef.current = analyticsState?.products_page_results_buffer || [];
    if (!analyticsState?.products_page_results_buffer) return;
    if (analyticsState?.products_page_results_buffer.length >= ANALYTICS_SEARCH_RESULTS_BUFFER_THRESHOLD)
      sendResultsDetails();
  }, [analyticsState?.products_page_results_buffer]);

  useEffect(() => {
    if (mode !== 'productsPageDropdownSearch') return;
    analyticsBufferRef.current = analyticsState?.products_page_dropdown_results_buffer || [];
  }, [analyticsState?.products_page_dropdown_results_buffer]);

  useEffect(() => {
    if (mode !== 'topNavSearch') return;
    analyticsBufferRef.current = analyticsState?.top_nav_results_buffer || [];
  }, [analyticsState?.top_nav_results_buffer]);

  useEffect(() => {
    if (mode !== 'homepageDropdownSearch') return;
    analyticsBufferRef.current = analyticsState?.homepage_results_dropdown_buffer || [];
  }, [analyticsState?.homepage_results_dropdown_buffer]);

  const initNewSearch = () => {
    dispatch(clearSearch(mode));
    registerNewSearch();
  }

  useEffect(() => {
    touchCookie();
    initNewSearch();

    window.addEventListener('beforeunload', () => {
      sendRemainingDetails(searchIdRef.current);
    });

    return () => {
      sendRemainingDetails(searchIdRef.current);
    }
  }, []);

  return <></>
};

const mapStateToProps = (state: ApplicationState) => ({
  analyticsState: state.userInterestsAnalytics,
});

export default connect(mapStateToProps)(SendSearchAnalyticsPlugin);
