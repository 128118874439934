import { MultiplePaypalFlowsModalProps } from '../../types/components/checkout/MultiplePaypalFlowsModal';

import { useState } from 'react';
import { useRouter } from 'next/router';

import BasicModal from '../shared/BasicModal';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';

const MultiplePaypalFlowsModal: React.FC<MultiplePaypalFlowsModalProps> = ({
  subscriptionRedirectUrl,
  addOnRedirectUrl,
  isOpen,
  setIsOpen,
}) => {
  const [subscriptionRedirectVisited, setSubscriptionRedirectVisited] = useState(false);
  const [addOnRedirectVisited, setAddOnRedirectVisited] = useState(false);

  const router = useRouter();

  const openRedirectUrl = (url: string) => {
    window.open(url, '_blank');
  }

  const openSubscriptionFlow = () => {
    openRedirectUrl(subscriptionRedirectUrl);
    setSubscriptionRedirectVisited(true);
    if (addOnRedirectVisited) router.push('/');
  }

  const openAddonFlow = () => {
    openRedirectUrl(addOnRedirectUrl);
    setAddOnRedirectVisited(true);
    if (subscriptionRedirectVisited) router.push('/');
  }

  return (
    <BasicModal
      visible={isOpen}
      setVisible={setIsOpen}
      content={''}
    >
      <div cy-test-id="multiple-paypal-flows-modal">
        <p className="text-white inter text-16 font-semibold leading-24">
          Two separate PayPal flows will be initiated for your subscription purchase and your add-on purchase.
          Click on the links below to start each one of them.
        </p>
        <div className="flex gap-4 mt-8 justify-center">
          <MainLinkButton
            onClick={openSubscriptionFlow}
            variant="blue"
            className="w-[250px]"
            testId="subscription-button"
          >
            Subscription purchase
          </MainLinkButton>
          <MainLinkButton
            onClick={openAddonFlow}
            variant="blue"
            className="w-[250px]"
            testId="addon-button"
          >
            Add-on purchase
          </MainLinkButton>
        </div>
      </div>
    </BasicModal>
  )
}

export default MultiplePaypalFlowsModal;
