export default function confirmSetup(
  stripeInstance: any,
  clientSecret: string,
  elements?: any,
  return_url?: string,
  redirect: string = 'always',
  successCallback?: Function,
  errorCallback?: Function,
): void {
  const onError = (error: any) => {
    if (!errorCallback) return;
    errorCallback(error);
  }

  const onSuccess = (result: any) => {
    if (!successCallback) return;
    successCallback(result);
  }

  const confirmSetupConfig: any = {
    elements,
    clientSecret,
    confirmParams: {},
    redirect,
  };

  if (return_url) confirmSetupConfig.confirmParams.return_url = return_url;

  stripeInstance.confirmSetup(confirmSetupConfig)
  .then(function (result: any) {
    result.error ?
      onError(result) :
      onSuccess(result);
  });
}
