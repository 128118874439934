import { useEffect, useRef } from 'react';

export default function DetectOutsideClick(props) {
  const { children, onOutsideClick, eventName = 'click' } = props;

  const elRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elRef.current && !elRef.current.contains(event.target)) {
        onOutsideClick && onOutsideClick();
      }
    };
    document.addEventListener(eventName, handleClickOutside, true);
    return () => {
      document.removeEventListener(eventName, handleClickOutside, true);
    };
  }, [onOutsideClick]);

  return <div ref={elRef}>{children}</div>;
}
