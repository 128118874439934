export function highlightKeywords(searchString: string, text: string): string {
  const keywords: string[] = searchString.split(' ');
  const pattern: string = keywords.map((keyword) => `\\b${escapeRegExp(keyword)}\\b`).join('|');
  const regex: RegExp = new RegExp(`(${pattern})`, 'gi');
  const highlightedText: string = text.replace(regex, '<b>$1</b>');
  return highlightedText;
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function shiftArrayElements(array: Array<any>): Array<any> {
  if (!array || array.length < 2) return array;
  const ret = [];
  for (let i = 1; i < array.length; i++) ret.push(array[i]);
  ret.push(array[0]);
  return ret;
}
