import { SubscriptionUpgradeModalProps, SubscriptionUpgradeModalStateProps } from '../../types/components/subscription-upgrade-modal/SubscriptionUpgradeModal';
import { ApplicationState } from '../../types/state/storeTypes';

import { Dialog } from '@headlessui/react';
import SubscriptionUpgrade from './SubscriptionUpgrade';

import { connect, useDispatch } from 'react-redux';

import { hideUpgradeSubscriptionModal } from '../../state/actions/subscriptionUpgradeActions';
const SubscriptionUpgradeModal: React.FC<SubscriptionUpgradeModalProps> = ({
  isOpen,
}) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(hideUpgradeSubscriptionModal());
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div
        className="fixed inset-0 flex items-center justify-center bg-black/60 overflow-x-hidden z-[100]"
        cy-test-id="subscription-upgrade-dialog"
      >
        <Dialog.Panel className="max-h-[100vh]">
          <SubscriptionUpgrade onClose={onClose} />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState): SubscriptionUpgradeModalStateProps => ({
  isOpen: state.subscriptionUpgrade.showUpgradeSubscriptionModal,
});

export default connect(mapStateToProps)(SubscriptionUpgradeModal);
