import store from '../../state/store';

import { LicenseTypeDetail } from '../../types/api/LicenseTypesTypes';

export function licenseByLicenseId(licenseId: number, licenseTypesData: Array<LicenseTypeDetail>): any {
  const match = licenseTypesData.find((lt) => lt.licenses.find((l) => l.id === licenseId));
  if (!match) return null;

  return {
    type: match,
    license: match.licenses.find((l) => l.id === licenseId)
  };
}

export function licenseNameById(licenseId: number, licenseTypesData: Array<LicenseTypeDetail>): any {
  const match = licenseTypesData.find((lt) => lt.licenses.find((l) => l.id === licenseId));
  if (!match) return null;

  if (match.licenses.length > 1) {
    const license = match.licenses.find((l) => l.id === licenseId);
    return `${match.name} (${license.range})`;
  }

  return match.name;
}