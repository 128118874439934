import { CreditPackPaymentProps } from '../../../types/components/credits/payment-block/CreditPackPayment';
import { PaymentMethod } from '../../../types/ui/paymentMethod';

import PurchaseCreditPackButton from '../../paypal/PurchaseCreditPackButton';
import PaymentMethodSelection from '../../payment/PaymentMethodSelection';
import CardSelectionAndPaymentBlock from '../../checkout/CardSelectionAndPaymentBlock';

import { useState } from 'react';

const CreditPackPayment: React.FC<CreditPackPaymentProps> = ({
  selectedOption,
  onPurchaseCompleted,
  selectedOptionValid,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>('card');

  const submitDisabled = (): boolean => {
    return !selectedOption || !selectedOptionValid;
  };

  return (
    <div cy-test-id="credit-pack-payment" className="flex flex-col gap-4">
      <PaymentMethodSelection
        onPaymentMethodSelected={setSelectedPaymentMethod}
        defaultSelection={selectedPaymentMethod}
      />
      {
        selectedPaymentMethod === 'paypal' ?
        <PurchaseCreditPackButton
          is_custom={selectedOption.isCustomCreditPack}
          recurring_credits={selectedOption.customCreditPackAmount}
          credit_pack_variant_id={selectedOption.data.id}
          disabled={submitDisabled()}
        /> :
        <CardSelectionAndPaymentBlock
          disableSubmit={submitDisabled()}
          purchasedCreditOption={selectedOption}
          onPurchaseCompleted={onPurchaseCompleted}
          doNotRedirectAfterUpgrade={true}
        />
      }
    </div>
  )
}

export default CreditPackPayment;
