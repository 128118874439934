import { useState, useRef } from 'react';
import axios from 'axios';
import { ENV } from '../../constants/environments';

import ActiveCampaignInputGroup from './activeCampaignInputGroup';
import ActiveCampaignMessage from './activeCampaignMessage';
import Captcha from '../shared/Captcha';
import { ThreeDots } from 'react-loader-spinner';

import { MainLinkButton } from '../styled/buttons/MainLinkButton';

const ActiveCampaignWidget: React.FC<any> = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [warning, setWarning] = useState<string>('');
  const [captchaPassed, setCaptchaPassed] = useState<boolean>(false);
  const [captchaValidated, setCaptchaValidated] = useState<boolean>(false);
  const [captchaToken, setCaptchaToken] = useState<string>('');

  const [contactCreated, setContactCreated] = useState<boolean>(false);
  const [conatctId, setContactId] = useState<string>('');
  const [contactAddedToList, setContactAddedToList] = useState<boolean>(false);

  const formRef = useRef(null);

  const onCaptchaPassed = (token: string) => {
    if (!token) return;
    setCaptchaToken(token);
    setCaptchaPassed(true);
  };

  const submit = (event) => {
    event.preventDefault();
    if (!captchaPassed) return;
    setError('');
    setWarning('');
    captchaValidated ? afterCaptchaValidated() : validateCaptcha();
  };

  const validateCaptcha = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/validate_captcha', { recaptchaToken: captchaToken });
      const { success, message } = response.data;

      if (!success) {
        setError(`reCAPTCHA verification failed: ${message}`);
        setLoading(false);
      } else {
        setCaptchaValidated(true);
        afterCaptchaValidated();
      }
    } catch (error) {
      setError(`Error during reCAPTCHA verification: ${error.message}`);
      setLoading(false);
    }
  };

  const afterCaptchaValidated = () => {
    if (!contactCreated) {
      addContact();
      return;
    }
    if (!contactAddedToList) addToList(conatctId);
  };

  const addContact = () => {
    setLoading(true);
    axios
      .post('/api/active-campaign/create-contact', {
        contact: {
          email: email,
          firstName: firstName,
          lastName: lastName
        }
      })
      .then(function (response) {
        setContactCreated(true);
        const id = response.data.contact.id;
        setContactId(id);
        addToList(id);
      })
      .catch(function (error) {
        setLoading(false);
        const userExists = error?.response?.data?.errors?.some(
          (error: any) => error.code === 'duplicate'
        );
        if (userExists)
          setWarning(
            'Congratulations, you already are an ActionVFX Subscriber! This email address exists in our Subscribers list.'
          );
        else setError('Something went wrong! Please try again');
      });
  };

  const addToList = (contactId: string) => {
    setLoading(true);
    axios
      .post('/api/active-campaign/add-contact-to-list', {
        contactList: {
          contact: contactId,
          list: parseInt(ENV.activeCampaignDefaultListId),
          status: 1
        }
      })
      .then(function (response) {
        setLoading(false);
        setContactAddedToList(true);
      })
      .catch(function (error) {
        setLoading(false);
        setError(
          'You were successfully onboarded, but something went wrong while adding you to the ActionVFX Subscribers.'
        );
      });
  };

  return (
    <>
      {(!contactCreated || !contactAddedToList) && (
        <form
          cy-test-id="active-campaign-form"
          ref={formRef}
          className="w-full md:max-w-[340px] block"
          onSubmit={(event) => submit(event)}>
          <h1 className="inter mb-4 font-medium text-[14px] leading-[22px] text-white uppercase">
            connect with us
          </h1>
          <p className="mb-4 font-light text-[14px] leading-[22px] text-[#7f898d]">
            Stay updated with ActionVFX news, offers, and promotions.
          </p>
          <div className="mb-6">
            <ActiveCampaignInputGroup
              label="First Name"
              placeholder="First Name"
              onChange={(value: string) => setFirstName(value)}
              required={true}
              type="text"
            />
            <ActiveCampaignInputGroup
              label="Last Name"
              placeholder="Last Name"
              onChange={(value: string) => setLastName(value)}
              required={true}
              type="text"
            />
            <ActiveCampaignInputGroup
              label="Email"
              placeholder="Email Address"
              onChange={(value: string) => setEmail(value)}
              required={true}
              type="email"
              autoComplete="email"
            />
          </div>
          {warning && <ActiveCampaignMessage type="warning" message={warning} />}
          {error && <ActiveCampaignMessage type="error" message={error} />}
          <Captcha onPassed={onCaptchaPassed} />
          {!loading && (
            <MainLinkButton
              type="submit"
              variant="blue"
              size="full"
              className="!h-[56px] cursor-pointer !text-white"
              disabled={!captchaPassed || !formRef?.current?.checkValidity()}>
              {error && (!contactCreated || !contactAddedToList) ? 'retry' : 'subscribe'}
            </MainLinkButton>
          )}
          {loading && (
            <div className="w-full pl-[calc(50%-40px)]" cy-test-id="active-campaign-loading-ui">
              <ThreeDots color="#00A1E0" />
            </div>
          )}
        </form>
      )}
      {contactCreated && contactAddedToList && (
        <div
          className="w-full max-w-[340px] block text-center text-[18px] leading-[29px] text-white"
          cy-test-id="active-campaign-success-message">
          Thanks for signing up!
        </div>
      )}
    </>
  );
};

export default ActiveCampaignWidget;
