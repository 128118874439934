import { IntervalToggleProps } from '../../types/components/subscription-upgrade-modal/IntervalToggle';
import { SubscriptionInterval } from '../../types/api/SubscriptionTypes';

import { Switch } from '@headlessui/react'

import { useState } from 'react';

export const activeIntervalLabelClass = 'text-white';
export const inactiveIntervalLabelClass = 'text-gray-600';

export const activeSwitchBackgroundClass = 'bg-a-blue';
export const inactiveSwitchBackgroundClass = 'bg-gray-600'

export const activeSwitchClass = 'translate-x-[22px]';
export const inactiveSwitchClass = 'translate-x-[3px]';

const IntervalToggle: React.FC<IntervalToggleProps> = ({ onSelectedInterval, selectedInterval }) => {
  const [switchEnabled, setSwitchEnabled] = useState(selectedInterval === 'year');

  const onSwitchChange = (enabled: boolean) => {
    const interval: SubscriptionInterval = enabled ? 'year' : 'month';
    onSelectedInterval(interval);
    setSwitchEnabled(enabled);
  }

  const renderIntervalLabel = (interval: SubscriptionInterval): React.ReactElement => {
    let title: string;
    switch (interval) {
      case 'month': title = 'Monthly'; break;
      case 'year': title = 'Yearly'; break;
      default: title = ''; break;
    };

    return (
      <span
        className={`inter text-16 leading-24 font-bold ${selectedInterval === interval ? activeIntervalLabelClass : inactiveIntervalLabelClass}`}
        cy-test-id={`interval-label-${interval}`}
      >
        {title}
      </span>
    )
  }

  return (
    <div className="items-center justify-center flex flex-col md:flex-row gap-5 md:gap-3" cy-test-id="interval-toggle">
      <div className="flex flex-row gap-4">
        {renderIntervalLabel('month')}
        <Switch
          checked={switchEnabled}
          onChange={onSwitchChange}
          className={`${
            switchEnabled ? activeSwitchBackgroundClass : inactiveSwitchBackgroundClass
          } focus-visible:outline-none relative inline-flex h-[31px] w-[51px] items-center rounded-full`}
        >
          <span
            cy-test-id="switch-button"
            className={`${
              switchEnabled ? activeSwitchClass : inactiveSwitchClass
            } inline-block h-[25px] w-[26px] transform rounded-full bg-white transition`}
          />
        </Switch>
        {renderIntervalLabel('year')}
      </div>
      <div
        className="content-center px-2.5 py-0.5 rounded-[5px] bg-a-green text-white inter uppercase text-12 leading-20 font-bold"
        cy-test-id="get-all-credits-banner"
      >
        Get all credits upfront
      </div>
    </div>
  )
};

export default IntervalToggle;
