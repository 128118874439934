import { USER_INTERESTS_ANALYTICS_ACTIONS } from '../../constants/actions';

import { ISearchResult } from '../../types/api/UserInterestAnalyticsTypes';
import { SearchMode } from '../../types/components/user-interest-analytics/SendSearchAnalyticsPlugin';

const {
  CLEAR_SEARCH,
  ADD_RESULT_TO_BUFFER,
  EMPTY_BUFFER,
} = USER_INTERESTS_ANALYTICS_ACTIONS;

export const clearSearch = (mode: SearchMode) => ({
  type: CLEAR_SEARCH,
  mode,
});

export const addResultToBuffer = (result: ISearchResult, mode: SearchMode) => ({
  type: ADD_RESULT_TO_BUFFER,
  mode,
  result,
});

export const emptyBuffer = (mode: SearchMode) => ({
  type: EMPTY_BUFFER,
  mode,
});
