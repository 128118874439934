import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { cacheLicenseTypes } from '../state/actions/licenseTypesActions';

import { ApplicationState } from '../types/state/storeTypes';
import { LicenseTypeDetail, LicenseTypeResponse } from '../types/api/LicenseTypesTypes';

export default function getLicenseTypes() {
  const licenseTypesUrl = '/api/license_types';

  const dispatch = useDispatch();
  const state: ApplicationState = useSelector((state) => state) as ApplicationState;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!!state.licenseTypes.licenseTypes) return;

    const getLicenseTypes = async () => {
      setLoading(true);
      const response: LicenseTypeResponse = await axios.get(licenseTypesUrl);
      setLoading(false);
      const licenseTypesResult: Array<LicenseTypeDetail> = response.data;
      dispatch(
        cacheLicenseTypes(licenseTypesResult)
      );
    }

    getLicenseTypes();
  }, [])

  return { licenseTypes: state.licenseTypes.licenseTypes, loading };
}
