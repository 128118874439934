import axios from 'axios';
import generateRandomKey from '../../helpers/generateRandomKey';
import { get, set } from 'js-cookie';

import { User } from '../../types/api/UsersTypes';
import { SearchType, ISearchEvent, ISearchEventResponse, ISearchResult, SearchResultType, IProductPageVisitEvent, IBlogArticleVisitEvent } from '../../types/api/UserInterestAnalyticsTypes';
import type { GenericResponse } from '../../types/api/Http';
import { CollectSearchResultsPluginProps } from '../../types/components/user-interest-analytics/CollectSearchResultsPlugin';
import { SearchMode } from '../../types/components/user-interest-analytics/SendSearchAnalyticsPlugin';
import { ProductSearchResult } from '../../types/api/ProductsIndexTypes';
import { ArticleResponse } from '../../types/pages/blog/BlogTypes';

import getCookie from '../../utils/getCookie';
import { BROWSER_FINGERPRINT_COOKIE_NAME } from '../../constants/utils';

function getUserType(user: User): 'IndividualUser' | 'BusinessUser' | 'BusinessStudio' {
  switch (user?.min_license_id) {
    case 6:
    case 5:
    case 4:
    case 3:
      return 'BusinessStudio';
    case 2:
      return 'BusinessUser';
    case 1:
    default:
      return 'IndividualUser';
  }
}

export async function createSearchEvent(
  search_type: SearchType,
  search_term: string,
  user?: User
) {
  const timestamp = Date.now();
  const search_url = window.location.href;
  const user_email = user?.email;
  const user_id = user?.id;
  const user_type = getUserType(user);
  const browser_fingerprint = getCookie(BROWSER_FINGERPRINT_COOKIE_NAME);
  const payload: ISearchEvent = {
    user_id,
    user_email,
    search_type,
    search_url,
    search_term,
    timestamp,
    browser_fingerprint,
  };
  if (user) payload.user_type = user_type;
  const url = '/api/user_interest_analytics/search_event';
  const result: GenericResponse<ISearchEventResponse> = await axios.post(url, payload);
  return result.data;
};

export async function storeSearchResults(
  search_id: string,
  payload: Array<ISearchResult>,
) {
  const url = `/api/user_interest_analytics/search_results_event/${search_id}`;
  const result: GenericResponse<ISearchEventResponse> = await axios.post(url, payload);
  return result.data;
}

export function getCollectSearchResultsPluginPropsFor(
  result: ProductSearchResult,
  libraryId: '2d' | '3d' | 'pfl',
  mode: SearchMode,
): CollectSearchResultsPluginProps {
  let collectionProp: ProductSearchResult;
  let categoryProp: ProductSearchResult;
  let productProp: ProductSearchResult;
  switch (result.type) {
    case 'Collection':
      collectionProp = result;
      break;
    case 'Category':
      categoryProp = result;
      break;
    default:
      break;
  }
  return {
    libraryId,
    mode,
    collection: collectionProp,
    category: categoryProp,
    product: productProp,
  };
}


export async function createProductPageVisitEvent(
  slug: string,
  name: string,
  product_type: SearchResultType,
  user?: User,
) {
  const timestamp = Date.now();
  const pageUrl = window.location.href;
  const browser_fingerprint = getCookie(BROWSER_FINGERPRINT_COOKIE_NAME);

  const payload: IProductPageVisitEvent = {
    timestamp,
    browser_fingerprint,
    slug,
    name,
    url: pageUrl,
    product_type,
    user_id: user?.id,
    user_email: user?.email,
  };
  if (user) payload.user_type = getUserType(user);

  const url = '/api/user_interest_analytics/product_page_visit_event';
  const result: GenericResponse<{ success: boolean }> = await axios.post(url, payload);
  return result.data;
}

export const createBlogArticleVisitEvent = async (
  article: ArticleResponse,
  user?: User,
) => {
  const timestamp = Date.now();
  const pageUrl = window.location.href;
  const browser_fingerprint = getCookie(BROWSER_FINGERPRINT_COOKIE_NAME);

  const payload: IBlogArticleVisitEvent = {
    timestamp,
    browser_fingerprint,
    slug: article.slug,
    article_id: article.id,
    article_title: article.title,
    url: pageUrl,
    user_id: user?.id,
    user_email: user?.email,
  };
  if (user) payload.user_type = getUserType(user);

  const url = '/api/user_interest_analytics/blog_article_visit_event';
  const result: GenericResponse<{ success: boolean }> = await axios.post(url, payload);
  return result.data;
}

export const touchCookie = () => {
  if (!!get(BROWSER_FINGERPRINT_COOKIE_NAME)) return;
  const browser_fingerprint = generateRandomKey();
  // In case actionvfx will still be around in 100 years
  set(BROWSER_FINGERPRINT_COOKIE_NAME, browser_fingerprint, { expires: 365 * 100 });
}

// TODO wrap all helpers in this object for testing
const userInterestAnalyticsHelpers = {
  createBlogArticleVisitEvent,
  touchCookie,
};

export default userInterestAnalyticsHelpers;
