import { PlanVariantResponse, SubscriptionInterval, SubscriptionPlanVariantDetail } from '../types/api/SubscriptionTypes';
import { LicenseDetail } from '../types/api/LicenseTypesTypes';
import { CreditPlanConfig } from '../types/helpers/credits';

import { getModifiedPrice } from './pages/collections/collectionsHelpers';

export const getVerboseInterval = (plan: PlanVariantResponse | CreditPlanConfig | SubscriptionPlanVariantDetail): string => {
  let interval: SubscriptionInterval = plan?.interval;
  switch (interval) {
    case 'month':
      return 'Monthly';
    case 'year':
    default:
      return 'Annual';
  }
};

export const getShortenedInterval = (plan: PlanVariantResponse | CreditPlanConfig | SubscriptionPlanVariantDetail): string => {
  let interval: SubscriptionInterval = plan?.interval;
  switch (interval) {
    case 'month':
      return 'mo';
    case 'year':
    default:
      return 'yr';
  }
};

export const getFullPrice = (plan: PlanVariantResponse, selectedLicense: LicenseDetail): number => {
  if (plan.interval !== 'year') {
    return parseFloat(plan.price);
  }
  const price = parseFloat(plan.price);
  const { getModifiedPrice } = subscriptionPlanHelpers;
  const calculatedPrice = getModifiedPrice(price, null, selectedLicense, true);
  return Math.round(calculatedPrice);
};

export const getMonthlyPrice = (
  plan: PlanVariantResponse,
  selectedLicense: LicenseDetail
): number => {
  const { getFullPrice } = subscriptionPlanHelpers;
  return getFullPrice(plan, selectedLicense) / 12;
};

const subscriptionPlanHelpers = {
  getVerboseInterval,
  getShortenedInterval,
  getFullPrice,
  getMonthlyPrice,
  getModifiedPrice
};

export default subscriptionPlanHelpers;
