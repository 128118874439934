import { UpdateLicenseBlockProps } from '../../types/components/license-profile/UpdateLicenseBlock';

import { useRouter } from 'next/router';

const UpdateLicenseBlock: React.FC<UpdateLicenseBlockProps> = ({ onLicenseUpdateOpened, onClose }) => {
  const router = useRouter();

  const startLicenseUpdateFlow = () => {
    if (onClose) onClose();
    router.push('/pricing');
    if (!onLicenseUpdateOpened) return;
    onLicenseUpdateOpened();
  }

  return (
    <div className="bg-a-dark-gray rounded-[5px] px-5 py-4 flex flex-row items-center" cy-test-id="update-license-block">
      <div className="flex flex-col gap-1">
        <span className="text-white inter text-16 leading-24 font-semibold" cy-test-id="title">
          Have the wrong license?
        </span>
        <span className="text-a-gray inter text-16 leading-24 font-normal" cy-test-id="subtitle">
          Update your license to stay compliant
        </span>
      </div>
      <span
        className="text-a-blue inter text-16 leading-24 font-semibold ml-auto cursor-pointer"
        onClick={startLicenseUpdateFlow}
        cy-test-id="update-link"
      >
        Update License
      </span>
    </div>
  );
};

export default UpdateLicenseBlock;
