import { CREDIT_ACTIONS } from '../../constants/actions';
const {
  INCREMENT_AVAILABLE_CREDIS,
  DECREMENT_AVAILABLE_CREDITS,
  SET_AVAILABLE_CREDITS,
} = CREDIT_ACTIONS;

export const incrementAvailableCredits = (amount: number) => ({
  type: INCREMENT_AVAILABLE_CREDIS,
  amount,
});

export const decrementAvailableCredits = (amount: number) => ({
  type: DECREMENT_AVAILABLE_CREDITS,
  amount,
});

export const setAvailableCredits = (amount: number) => ({
  type: SET_AVAILABLE_CREDITS,
  amount,
});
