import Link from "next/link";

export const defaultStyle = "eurostile flex items-center justify-center w-full h-[46px] font-semibold leading-24 bg-red-800 hover:bg-red-900 text-white text-[17px] font-bold rounded-[5px] uppercase";
export const collectionsSidebarStyle = "eurostile flex items-center justify-center w-full h-[46px] font-semibold leading-24 bg-red-800 hover:bg-red-900 text-white text-[17px] font-bold rounded-[5px] uppercase"
export const collectionsPageSidebarStyle = "eurostile flex items-center justify-center w-full h-[56px] font-semibold leading-24 bg-red-800 hover:bg-red-900 text-white mt-[24px] text-[17px] font-bold rounded-[5px] uppercase"
export const topNavStyle = "inter flex items-center justify-start whitespace-nowrap w-full h-[28px] px-2 font-semibold leading-24 bg-red-800 hover:bg-red-900 text-white text-[10px] 2xl:text-[14px] font-bold rounded-[5px] uppercase"

export const BlackFridayButton: React.FC<{
  type: "collections" | "collectionsPage" | "topNav";
}> = ({ type }) => {
  const determineStyle = () => {
    switch (type) {
      case "collections":
        return collectionsSidebarStyle;
      case "collectionsPage":
        return collectionsPageSidebarStyle;
      case "topNav":
        return topNavStyle;
      default:
        return defaultStyle;
    }
  };
  return (
    <></>
    // <Link className={determineStyle()} href="/pricing" data-cy="black-friday-button">
    //   get 30% off
    // </Link>
  );
}