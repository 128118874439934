import { BuyMoreCreditsDialogMode } from '../../types/components/credits/BuyMoreCreditsDialog';
import { BUY_MORE_CREDITS_ACTIONS } from '../../constants/actions';
const { SHOW_MODAL, HIDE_MODAL } = BUY_MORE_CREDITS_ACTIONS;

export const showModal = (mode: BuyMoreCreditsDialogMode, creditsMissing?: number) => ({
  type: SHOW_MODAL,
  creditsMissing,
  modalMode: mode,
});

export const hideModal = () => ({ type: HIDE_MODAL });
