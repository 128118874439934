import React, { useState } from 'react';
import Link from 'next/link';
import {
  TopNavigationAssets,
  TopNavigationLearn,
} from '../staticData/Navigation';
import { LogoFull } from '../modules/Icons';
import { PersonIcon, ExitIcon, DownChevronIcon } from '../modules/Icons';
import { AUTH_ACTIONS } from '../constants/actions';
import UserMenu from '../components/shared/UserMenu';
import { BlackFridayButton } from '../components/shared/BlackFridayButton';

const MobileNav: React.FC<any> = (props) => {
  const { auth, router, setMobileNavOpen, mobileNavOpen, subscription } = props;
  const [openDropdownKey, setOpenDropdownKey] = useState(null);

  const linkClasses = 'text-white eurostile uppercase text-[16px]';

  return (
    <div className="mt-[-70px] z-50 bg-a-darker-gray py-16">
      <div className="flex mb-[-20px]">
        <div className="ml-8 md:flex md:items-center md:gap-12">
          <Link className="block" href="/">
            <span className="sr-only">Home</span>
            <LogoFull className="w-full md:w-42 lg:w-42 xl:w-60" />
          </Link>
        </div>
        <ExitIcon onClick={() => setMobileNavOpen(false)} className="absolute right-4 md:right-8" />
      </div>
      <div className="p-8">
        <div className={''}>
          <nav aria-label="Site Nav">
            <ul className="flex flex-col gap-6">
              {!auth.authenticated && (
                <button className="mt-4 rounded-[5px] py-2 bg-a-dark-gray mb-4 md:w-1/3 md:h-[60px]">
                  <Link
                    id="login-button"
                    className="text-a-light-gray text-[16px] tracking-wider whitespace-nowrap hover:text-white fill-a-light-gray hover:fill-white"
                    href="#sign-in"
                    // href="/users/login"
                    onClick={() =>
                      props.dispatch(
                        { type: AUTH_ACTIONS.AUTH_SHOW_LOGIN },
                        setMobileNavOpen(!mobileNavOpen)
                      )
                    }
                  >
                    <PersonIcon className="inline mb-1 mr-2 fill-inherit" />
                    Sign In
                  </Link>
                </button>
              )}

              {auth.authenticated && <div></div>}

              {TopNavigationAssets.map((item, k) => (
                <li key={k}>
                  <a className={`Inter font-light`} href={item.href}>
                    <div className="flex flex-row gap-4">
                      <div
                        className={`flex fill-[#24C1FF] scale-[130%] ${
                          !item.iconFilled ? 'w-[24px]' : ''
                        }`}
                        style={{ fillOpacity: '1' }}
                      >
                        {item.iconFilled}
                      </div>
                      <div className="flex flex-col">
                        <div className={`text-white uppercase gap-3`}>{item.text} </div>
                        <p className="text-xs text-a-light-gray">{item.description}</p>
                      </div>
                    </div>
                  </a>
                </li>
              ))}

              {/* DIVIDER */}
              <div className="border-b-2 w-[100%] py-2 border-a-dark-gray"></div>

              {TopNavigationLearn.map((item, k) => (
                <li key={k}>
                  <a className={`Inter font-light text-white uppercase`} href={item.href}>
                    {item.text}
                    {item.children?.length > 0 ? (
                      <DownChevronIcon
                        className="inline mb-1 ml-2 stroke-white"
                        onClick={() => setOpenDropdownKey(openDropdownKey === k ? null : k)}
                        style={
                          openDropdownKey === k ? { transform: 'rotate(180deg) scaleX(-1)' } : null
                        }
                      />
                    ) : null}
                  </a>
                  {item.children?.length > 0 && (
                    <ul
                      className={`${openDropdownKey === k ? 'flex' : 'hidden'} px-4 flex-col gap-2`}
                    >
                      {item.children.map((subitem, j) => (
                        <li className="pt-2" key={j}>
                          <Link
                            className={`Inter font-light font-[12px] text-a-light-gray uppercase fill-a-light-gray hover:fill-a-blue`}
                            href={subitem.href}
                            // target={subitem.target}
                          >
                            {subitem.icon} {subitem.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}

              <li>
                <Link
                  className={`${linkClasses} ${
                    router.pathname.includes('pricing') ? 'text-a-blue' : 'text-white inter'
                  }`}
                  href="/pricing"
                >
                  {!auth?.subscription
                    ? 'Pricing'
                    : 'Upgrade' }
                </Link>
              </li>

              {auth.authenticated !== undefined && !subscription && <BlackFridayButton type="collections" />}
              {auth.authenticated !== undefined && subscription && !subscription.active && <BlackFridayButton type="topNav" />}
            </ul>
          </nav>

          {auth.authenticated && (
            <div>
              <div className="border-b-2 w-[100%] py-4 border-a-dark-gray"></div>
              <div className={`w-full mt-2 pt-6`}>
                <UserMenu />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
