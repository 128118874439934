export const messagePresets = {
  requires_login: {
    type: 'info',
    body: 'You must be logged in to access this feature.'
  },
  generic_error: {
    type: 'error',
    body: 'Something went wrong.'
  },
  generic_success: {
    type: 'success',
    body: 'Action completed successfully.'
  }
};