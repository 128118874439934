import { ColoredSidebarNotificationProps } from '../../types/components/shared/ColoredSidebarNotification';

import * as uiConstants from '../../constants/ui';
import isMobile from '../../hooks/isMobile';

const { NOTIFICATION_STYLE, NOTIFICATION_MOBILE_STYLE } = uiConstants;

export const redColorClass = 'bg-red-900';
export const blueColorClass = 'bg-a-blue';
export const greenColorClass = 'bg-a-green';
export const yellowColorClass = 'bg-a-yellow';

const ColoredSidebarNotification: React.FC<ColoredSidebarNotificationProps> = (props) => {
  const { color, body } = props;
  const mobile = isMobile();

  const getSidebarColorClass = (): string => {
    switch (color) {
      case 'red': return redColorClass;
      case 'blue': return blueColorClass;
      case 'green': return greenColorClass;
      case 'yellow': return yellowColorClass;
      default: return '';
    }
  }

  return (
    <div
      cy-test-id="colored-sidebar-notification"
      style={mobile ? NOTIFICATION_MOBILE_STYLE : NOTIFICATION_STYLE}
      className="py-4 px-6 rounded-[5px] flex align-start gap-3 flex-[1_0_0%] bg-gray-1000 relative"
    >
      <span cy-test-id="colored-sidebar" className={`w-2 h-full absolute top-0 left-0 ${getSidebarColorClass()}`}/>
      {body}
    </div>
  )
};

export default ColoredSidebarNotification;
