import { LicenseId, LicenseTypeId } from '../types/api/LicenseTypesTypes';

export const licenseTypesData = [
  {
    id: 1 as LicenseTypeId,
    name: 'Individual',
    description: 'Licensed to 1 person only',
    pricing_description: 'Strictly forbidden for use by teams or studios',
    licenses: [
      {
        id: 1 as LicenseId,
        range: 'Up to $200k',
        price_factor: 1,
        subscription_price_factor: '1.0'
      }
    ]
  },
  {
    id: 2 as LicenseTypeId,
    name: 'Startup',
    description: 'Under $3M per year in revenue or funding',
    pricing_description: 'Licensed to your entire team',
    licenses: [
      {
        id: 2 as LicenseId,
        range: 'Under $3M',
        price_factor: 3,
        subscription_price_factor: '1.00001'
      }
    ]
  },
  {
    id: 3 as LicenseTypeId,
    name: 'Enterprise',
    description: 'Required if annual revenue is above $3M',
    pricing_description: 'Select revenue below to get instant pricing',
    licenses: [
      {
        id: 3 as LicenseId,
        range: '$3M-$20M',
        price_factor: 6,
        subscription_price_factor: '1.7'
      },
      {
        id: 4 as LicenseId,
        range: '$21M-$49M',
        price_factor: 9,
        subscription_price_factor: '2.5'
      },
      {
        id: 5 as LicenseId,
        range: '$50M-$99M',
        price_factor: 12,
        subscription_price_factor: '3.0'
      },
      {
        id: 6 as LicenseId,
        range: 'Over $100M',
        price_factor: 16,
        subscription_price_factor: '4.0'
      }
    ]
  }
];

export const licenses = licenseTypesData.reduce((flattenedArray, element) => {
  return [...flattenedArray, ...element.licenses];
}, []);
