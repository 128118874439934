import { PlanPriceTagProps } from '../../types/components/shared/PlanPriceTag';

export const wrapperHorizontalAlignmentClass = 'flex-row gap-3';
export const wrapperVerticalAlignmentClass = 'flex-col gap-1';

const PlanPriceTag: React.FC<PlanPriceTagProps> = ({
  plan,
  freeForever,
  shortenIntervalStrings,
  alignment = 'horizontal',
  displayDecimals = false,
}) => {
  const getPlanPrice = (): string => {
    const price = plan?.adjusted_price || plan?.price || '0';
    return parseFloat(price).toFixed(displayDecimals ? 2 : 0);
  }

  const getPlanInterval = (): string => {
    if (!shortenIntervalStrings) return plan.interval;
    switch (plan.interval) {
      case 'month': return 'mo';
      case 'year': return 'yr';
      default: return '';
    }
  }

  return (
    <div className={`
      flex items-center md:h-[44px] mb-6
      ${alignment === 'horizontal' ? wrapperHorizontalAlignmentClass : wrapperVerticalAlignmentClass}
    `} cy-test-id="plan-price-tag">
      {
        plan &&
        <>
          {
            plan?.adjusted_price &&
            <span cy-test-id="old-price-block" className="text-[18px] leading-[18px] text-red-800 line-through">{`$${plan?.price?.split('.')[0]}`}</span>
          }
          <div className="flex flex-row items-center gap-2">
            <p className={`${plan.adjusted_price ? 'text-[28px] md:text-[36px]' : 'max-xl:text-[50px] xl:text-[36px] 2xl:text-[50px]'} font-bold md:leading-[44px] text-white`} cy-test-id="price-block">
              <span className="text-[24px] md:align-bottom inline-block">$</span>
              {getPlanPrice()}
            </p>
            <p
              className="text-a-gray text-[16px] leading-[24px]" cy-test-id="interval">
              / {getPlanInterval()}
            </p>
          </div>
        </>
      }
      {
        freeForever &&
        <p className="text-[50px] font-bold md:leading-[44px]" cy-test-id="free-forever-block">
          <span className="text-[24px] md:align-bottom inline-block">$</span>0
        </p>
      }
    </div>
  );
}

export default PlanPriceTag;
