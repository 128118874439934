const outdatedPlanIds = {
  individualOrStartup: [
    128,
    129,
    130,
    131,
    132,
    133,
  ],
  enterprise: [
    134,
    135,
    136,
    137,
    138,
    139,
  ],
};

export default outdatedPlanIds;
