import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { TextInput, PasswordInput } from '../../modules/Inputs';
import { Button } from '../../modules/Buttons';
import { ApplicationState } from '../../types/state/storeTypes';
import Messages from './Messages';
import { AUTH_ACTIONS } from '../../constants/actions';
import { setMessages } from '../../state/actions/messagesActions';
import { signIn } from 'next-auth/react';
import {
  CheckoutLoginProps,
  CheckoutLoginState,
  LoginFormData
} from '../../types/pages/checkout/CheckoutTypes';
import { Loader } from './Loader';
import AfterLogin from '../helpers/AfterLogin';

const { AUTH_HIDE_LOGIN } = AUTH_ACTIONS;

class LoginForm extends React.Component<CheckoutLoginProps, CheckoutLoginState> {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      loading: false,
      showAfterLogin: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this.forgotPasswordLink = this.forgotPasswordLink.bind(this);
  }

  onLoginSuccess() {
    this.setState({ showAfterLogin: true });
  }

  onAfterLoginSuccess() {
    this.setState({ loading: false, showAfterLogin: false });
    if (this.props.handleSuccess) this.props.handleSuccess();
    this.props.dispatch({ type: AUTH_HIDE_LOGIN });
  }

  onLoginError(result) {
    if (result.error.includes('423')) {
      this.props.dispatch(
        setMessages([
          {
            type: 'error',
            body: 'Your account is currently locked. To regain access, please contact our team at support@actionvfx.com.'
          }
        ])
      );
      this.setState({ loading: false });
      return;
    }

    this.props.dispatch(
      setMessages([
        {
          type: 'error',
          body: 'Invalid credentials'
        }
      ])
    );
    this.setState({ loading: false });
  }

  clearMessages() {
    this.setState({ errors: [] });
  }

  async handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const formData: FormData = new FormData(event.target as HTMLFormElement);
    const entries = Object.fromEntries(formData.entries());
    const data: LoginFormData = {
      email: String(entries.email),
      password: String(entries.password)
    };

    const errors: string[] = this.checkEntries(data);
    if (errors.length > 0) return this.setState({ errors });

    this.setState({ loading: true });

    const result = await signIn('credentials', {
      email: entries.email,
      password: entries.password,
      redirect: false
    });

    result.ok ? this.onLoginSuccess() : this.onLoginError(result);
  }

  checkEntries(formData: LoginFormData): string[] {
    let errors: string[] = [];

    if (!formData.email) errors.push('Email is required');
    if (!formData.password) errors.push('Password is required');

    return errors;
  }

  forgotPasswordLink = (): React.ReactNode => {
    return (
      <Link href="/users/reset" className="text-a-blue">
        Forgot password?
      </Link>
    );
  };

  renderErrors = (): JSX.Element => {
    let errors: string[] = this.state.errors;
    const { error } = this.props;
    const duplicateError: boolean = errors.includes(error);
    if (error && !duplicateError) errors.push(error);
    return <Messages
      format="inline"
      messages={[{ body: errors.join('. '), type: 'error' }]}
      onCloseMessage={() => this.clearMessages()}
    />;
  };

  render() {
    const { loading, submitBtnText } = this.props;
    const forgotPassword: React.ReactNode = this.forgotPasswordLink();
    return (
      <form
        className="text-white mx-auto w-2/3 md:w-1/3 flex flex-col gap-6"
        onSubmit={this.handleSubmit}>
        {this.state.loading && <Loader />}
        <div className="text-center font-semibold text-[28px]">Log In To Your Account</div>
        {this.renderErrors()}
        <TextInput
          className="full email-input"
          label="email"
          name="email"
          placeholder="Email Address"
          autoComplete="email"
        />

        <div className="">
          <PasswordInput
            className="full password-input"
            label="password"
            name="password"
            placeholder="Password"
            autoComplete="current-password"
          />

          <div className="text-right my-3 text-[15px]">{forgotPassword}</div>
        </div>

        <Button
          disabled={loading}
          disabledText="loading..."
          type="submit"
          text={submitBtnText}
          className="uppercase bg-a-blue rounded-[5px] pt-4 pb-5 text-[18px] eurostile font-semibold h-[56px] hover:bg-[#24C1FF]"
        />

        <div className="text-[15px] text-a-light-gray">
          Don't have an account yet?{' '}
          <Link
            className="text-a-blue"
            href="/pricing"
            onClick={() => this.props.dispatch({ type: AUTH_HIDE_LOGIN })}>
            Get started by choosing a plan
          </Link>
        </div>
        {this.state.showAfterLogin && (
          <AfterLogin afterLoginTasksCompleted={() => this.onAfterLoginSuccess()} process="login" />
        )}
      </form>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  error: state.auth.error ? 'Incorrect email and password combination.' : null,
  loading: state.auth.loading,
  authenticated: state.auth.authenticated
});

export default connect(mapStateToProps)(LoginForm);
