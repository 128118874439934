const individualFormInputs = ({ renderInput }) => {
  return (
    <>
      <div className="flex flex-col gap-4 mb-4 lg:flex-row lg:gap-2 lg:mb-2">
        {renderInput('first_name', 'First name', 'First Name')}
        {renderInput('last_name', 'Last name', 'Last Name')}
      </div>

      <p className="text-[12px] font-light text-gray-600 mb-[32px]">
        The Licensee for an "Individual" license must be the person using the purchased products,
        not a company.
      </p>
      {renderInput('email', 'Contact email', 'Contact Email')}
    </>
  );
};

const startupFormInputs = ({ renderInput }) => {
  return (
    <>
      <div className="flex flex-col gap-4 mb-4 lg:flex-row lg:gap-2 lg:mb-2">
        {renderInput('company_name', 'Company name', 'Business Legal Name')}
      </div>
      <p className="text-[12px] font-light text-gray-600 mb-[32px]">
        Use your registered business name. If not a registered business, use your legal first and
        last name.
      </p>
      <div className="flex flex-col gap-4 mb-[32px] lg:flex-row lg:gap-2">
        {renderInput('first_name', 'First name', 'First Name (optional)')}
        {renderInput('last_name', 'Last name', 'Last Name (optional)')}
      </div>
      <div className="my-10">{renderInput('email', 'Contact email', 'Contact Email')}</div>
    </>
  );
};

const enterpriseFormInputs = ({ renderInput }) => {
  return (
    <>
      <div className="mb-[40px]">
        {renderInput('company_name', 'Company name', 'Business Legal Name')}
      </div>
      <div className="mb-10">{renderInput('email', 'Contact email', 'Contact Email')}</div>
    </>
  );
};

const individualFormContent = () => {
  return (
    <>
      <p className="text-[16px] text-white">Licensee Name</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px] lg:mb-[48px]">
        The name of the person licensed to use the products purchased.
      </p>
      <p className="text-[16px] text-white">Contact Email</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        Email of the point of contact to discuss all licensing matters.
      </p>
      <p className="text-[16px] text-white">Licensee Address</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        The physical address of the licensee.
      </p>
    </>
  );
};

const startupFormContent = () => {
  return (
    <>
      <p className="text-[16px] text-white">Licensee Name</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px] lg:mb-[140px]">
        The name of the entity licensed to use the products purchased.
      </p>
      <p className="text-[16px] text-white">Contact Email</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        Email of the point of contact to discuss all licensing matters.
      </p>
      <p className="text-[16px] text-white">Licensee Address</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        The physical address of the licensee.
      </p>
    </>
  );
};

const enterpriseFormContent = () => {
  return (
    <>
      <p className="text-[16px] text-white">Licensee Name</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[18px]">
        The name of the entity licensed to use the products purchased.{' '}
      </p>
      <p className="text-[16px] text-white">Contact Email</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        Email of the point of contact to discuss all licensing matters.{' '}
      </p>
      <p className="text-[16px] text-white">Licensee Address</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        The physical address of the licensee.{' '}
      </p>
    </>
  );
};

export {
  individualFormInputs,
  startupFormInputs,
  enterpriseFormInputs,
  individualFormContent,
  startupFormContent,
  enterpriseFormContent
};
