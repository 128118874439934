import { buildPrice } from '../helpers/pages/collections/collectionsHelpers';
import { ButtonProps, IconButtonProps } from '../types/modules/ButtonsTypes';

// common button
export const Button: React.FC<ButtonProps> = (props) => {
  const { text, disabledText } = props;
  const loadingText: string = disabledText ? disabledText : text;
  const disabled: boolean = props.disabled || false;
  const buttonText: string = props.disabled ? loadingText : text;
  return (
    <button
      className={`button ${props.className}`}
      onClick={props.onClick}
      disabled={disabled}
      type={props.type || 'button'}
    >
      {buttonText}
    </button>
  );
};

// common button with icon
export const IconButton: React.FC<IconButtonProps> = (props) => {
  const { text, disabledText, iconLeft, className, disabled, icon } = props;
  const loadingText: string = disabledText ? disabledText : text;
  const buttonText: string = disabled ? loadingText : text;
  const buttonClass: string = `button icon ${className} ${iconLeft ? 'icon-left' : 'icon-right'}`;
  return (
    <button onClick={props.onClick} disabled={!!disabled} className={buttonClass}>
      {iconLeft && <span>{icon}</span>}
      {buttonText}
      {!iconLeft && <span>{icon}</span>}
    </button>
  );
};
