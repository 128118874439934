import { LICENSE_FLOW_ACTIONS } from '../../constants/actions';
const {
  SHOW_UPDATE_LICENSE_MODAL,
  HIDE_UPDATE_LICENSE_MODAL,
} = LICENSE_FLOW_ACTIONS;

export const showUpdateLicenseModal = () => ({
  type: SHOW_UPDATE_LICENSE_MODAL,
});

export const hideUpdateLicenseModal = () => ({
  type: HIDE_UPDATE_LICENSE_MODAL,
});
