import { EVENT_TRACKING_ACTIONS } from '../../constants/actions';
const {
  CURRENT_PRODUCT,
  HOMEPAGE,
  CONVERSION,
  PURCHASE,
  CHECKOUT,
} = EVENT_TRACKING_ACTIONS;
const {
  SET_PRODUCT,
  CLEAR_PRODUCT,
} = CURRENT_PRODUCT;
const {
  SET_VISITING,
  CLEAR_VISITING,
} = HOMEPAGE;
const {
  SET_SUBSCRIBED,
  CLEAR_SUBSCRIBED,
} = CONVERSION;
const {
  SET_CREDIT_PACK,
  CLEAR_CREDIT_PACK,
} = PURCHASE;
const {
  SET_INITIATE_CHECKOUT,
  CLEAR_INITIATE_CHECKOUT,
} = CHECKOUT;

import { CollectionsShowResponse } from '../../types/api/CollectionsShowTypes';
import { SubscriptionDetail, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { CreditPackResponse } from '../../types/api/CreditsTypes';

export const setCurrentProduct = (product: CollectionsShowResponse) => ({
  product,
  type: SET_PRODUCT,
});

export const clearCurrentProduct = () => ({
  type: CLEAR_PRODUCT,
});

export const setHomepageVisited = () => ({
  type: SET_VISITING,
});

export const clearHomepageVisited = () => ({
  type: CLEAR_VISITING,
});

export const setSubscribedToPlan = (plan: SubscriptionDetail) => ({
  plan,
  type: SET_SUBSCRIBED,
});

export const clearSubscribedToPlan = () => ({
  type: CLEAR_SUBSCRIBED,
});

export const setPurchasedCreditPack = (creditPack: CreditPackResponse, customAmount?: number) => ({
  creditPack,
  customAmount,
  type: SET_CREDIT_PACK,
});

export const clearPurchasedCreditPack = () => ({
  type: CLEAR_CREDIT_PACK,
});

export const setInitiateCheckout = (planVariant: PlanVariantResponse) => ({
  type: SET_INITIATE_CHECKOUT,
  checkoutDetails: { planVariant },
});

export const clearInitiateCheckout = () => ({
  type: CLEAR_INITIATE_CHECKOUT,
});
