import { useState } from 'react';
import { BasicInput } from '../../modules/Inputs';

const ActiveCampaignInputGroup: React.FC<any> = (props) => {
  const { label, placeholder, onChange, pattern, required = false, type } = props;

  const [touched, setTouched] = useState<boolean>(false);

  const onFocus = () => {
    if (touched) return;
    setTouched(true);
  };

  return (
    <div cy-test-id="active-campagin-input-group" className="w-full mb-2.5">
      <span className="text-white text-[14px] leading-[22px] mb-[5px] block">
        {`${label}${required ? ' *' : ''}`}
      </span>
      <BasicInput
        label={label}
        type={type}
        className={`full email-input ${
          touched ? 'invalid:border-red-800 invalid:border-[1px]' : ''
        }`}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        onFocus={() => onFocus()}
        required={required}
        pattern={pattern}
      />
    </div>
  );
};

export default ActiveCampaignInputGroup;
