import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@headlessui/react';
import { ExitIcon } from '../../modules/Icons';
import { FormProgressBar } from '../../components/shared/FormProgressBar';
import { LicenseConfirmationFlowProps } from '../../types/components/license-profile/licenseProfileConfirmation';
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { useLicenseFlow } from '../../hooks/useLicenseFlow';
import { FlowContent } from './FlowContent';

const LicenseConfirmationFlow: React.FC<LicenseConfirmationFlowProps> = ({
  user,
  subscription,
  onClose,
  open,
  isUpdate
}) => {
  const { currentStep, showLicenseError, flowState, handleNext, handleBack, handleChange } =
    useLicenseFlow(subscription, onClose, user);

  const [licenseeProfileExist, setLicenseeProfileExist] = React.useState(false);

  const [confirmLicenseContent, setConfirmLicenseContent] = React.useState({
    'License ID': '',
    'Licensed to': '',
    'Annual Revenue': '',
    'Contact Person': '',
    'Contact Email': '',
    Address: ''
  });

  useEffect(() => {
    if (user?.team?.licensee_profile) {
      // If licensee_profile does exist
      setLicenseeProfileExist(true);
    }
  }, [user?.team?.licensee_profile]);

  const progressPercentage = useMemo(() => {
    const totalSteps = flowState.flowConfig?.length + (showLicenseError ? 1 : 0);
    return ((currentStep + (showLicenseError ? 1 : 0)) / (totalSteps - 1)) * 100;
  }, [currentStep, showLicenseError, flowState.flowConfig?.length]);

  return (
    <Dialog cy-test-id="flow-content" open={open} onClose={onClose} className="relative z-[99]">
      <div className="fixed inset-0 bg-black/80" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center text-center">
          <Dialog.Panel className="w-full max-w-[860px] transform overflow-hidden rounded-[10px] px-5 lg:px-[48px] pt-[36px] pb-[50px] text-left align-middle shadow-xl transition-all bg-[#252A2D]">
            <div className="eurostile text-[18px] md:text-[24px] lg:text-[32px] font-medium leading-6 !text-white uppercase">
              Confirm License
            </div>

            <FormProgressBar
              bgColor="bg-gray-700"
              className="bg-a-blue !h-[4px]"
              progressPct={progressPercentage}
              cornered={true}
            />

            <ExitIcon
              className="ExitIcon absolute top-6 right-6 w-6 h-6 text-gray-500 cursor-pointer"
              onClick={onClose}
            />

            <FlowContent
              flowState={flowState}
              showLicenseError={showLicenseError}
              handleNext={handleNext}
              handleBack={handleBack}
              handleChange={handleChange}
              user={user}
              subscription={subscription}
              currentStep={currentStep}
              isUpdateProfile={licenseeProfileExist}
              confirmLicenseContent={confirmLicenseContent}
              setConfirmLicenseContent={setConfirmLicenseContent}
              onClose={onClose}
              isUpdate={isUpdate}
            />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.user as User | null,
  subscription: state.auth.subscription as SubscriptionDetail | null
});

export default connect(mapStateToProps)(LicenseConfirmationFlow);
