import {
  InitializePaypalSubscriptionPayload,
  InitializePaypalPaymentResponse,
} from '../types/helpers/paypalHelpers';
import { GenericResponse } from '../types/api/Http';
import { User } from '../types/api/UsersTypes';
import { CreditPackResponse } from '../types/api/CreditsTypes';

import { ENV } from '../constants/environments';

import axios, { AxiosError } from 'axios';

const apiUrl: string = `${ENV.api.baseURL}`;
// exporting for testing purposes
export const initializeSubscriptionUrl = `${apiUrl}/orders/paypal/initialize_subscription`;
export const purchaseCreditPackUrl = `${apiUrl}/orders/paypal`;

const initializeSubscription = async (
  payload: InitializePaypalSubscriptionPayload,
  user: User,
): Promise<GenericResponse<InitializePaypalPaymentResponse> | AxiosError> => {
  try {
    const requestConfig = {
      headers: {
        authorization: user.token,
      },
    };
    const result: GenericResponse<InitializePaypalPaymentResponse> = await axios.post(initializeSubscriptionUrl, payload, requestConfig);
    return result;
  } catch (error) {
    return error;
  }
};

const purchaseCreditPack = async (
  creditPack: CreditPackResponse,
  user: User,
): Promise<GenericResponse<InitializePaypalPaymentResponse> | AxiosError> => {
  try {
    const payload = {
      credit_pack_variant_id: creditPack.id,
      is_credit_pack_variant_order: true,
    };
    const requestConfig = {
      headers: {
        authorization: user.token,
      },
    };
    const result: GenericResponse<InitializePaypalPaymentResponse> = await axios.post(purchaseCreditPackUrl, payload, requestConfig);
    return result;
  } catch (error) {
    return error;
  }
}

const purchaseCustomCreditsAmount = async (
  recurring_credits: string,
  plan_id: string,
  user: User,
): Promise<GenericResponse<InitializePaypalPaymentResponse> | AxiosError> => {
  try {
    const payload = {
      recurring_credits,
      plan_id,
      is_custom: true,
      is_credit_pack_variant_order: true,
    };
    const requestConfig = {
      headers: {
        authorization: user.token,
      },
    };
    const result: GenericResponse<InitializePaypalPaymentResponse> = await axios.post(purchaseCreditPackUrl, payload, requestConfig);
    return result;
  } catch (error) {
    return error;
  }
}

const paypalHelpers = {
  initializeSubscription,
  purchaseCreditPack,
  purchaseCustomCreditsAmount,
};

export default paypalHelpers;
