import React from 'react';
import Head from 'next/head';
import { HeadContentProps } from '../types/layout/LayoutTypes';
import { seo } from '../staticData/Seo';

const HeadContent: React.FC<HeadContentProps> = (props) => {
  const { title, description, keywords, icon, og, canonical } = props;
  const titleContent: string = title ? `${title} | ActionVFX` : seo.title;
  const descriptionContent: string = description ? description : seo.description;
  const keywordsContent: string = keywords ? keywords.join(',') : seo.keywords.join(',');
  const iconContent: string = icon ? icon : seo.icon;
  const ogTitle: string = og && og.title ? `ActionVFX | ${og.title}` : seo.og.title;
  const ogDescription: string = og && og.description ? og.description : seo.og.description;
  const ogType: string = og && og.type ? og.type : seo.og.type;
  const ogUrl: string = og && og.url ? og.url : seo.og.url;
  const ogImage: string = og && og.image ? og.image : seo.og.image;

  return (
    <>
      <Head>
        <title>{titleContent}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={descriptionContent} />
        <meta name="keywords" content={keywordsContent} />

        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={descriptionContent || ogDescription} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content={ogType} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ActionVFX" />
        <meta name="twitter:title" content={titleContent} />
        <meta name="twitter:description" content={descriptionContent || ogDescription} />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={ogImage} name="twitter:image:src" />

        <meta name="p:domain_verify" content="37739a1ab03ca52a2e4f56506d3bf66a" />
        <meta name="facebook-domain-verification" content="05ykfleqlwfnouthq29nzqlc69kkpe" />

        <link rel="icon" href={iconContent} />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        {canonical && <link rel="canonical" href={`https://www.actionvfx.com/${canonical}`} />}
        {props.children}

        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <link rel="stylesheet" href="https://use.typekit.net/ptu4myo.css" />
      </Head>
    </>
  );
};

export default HeadContent;
