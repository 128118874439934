export const seo = {
  title: 'ActionVFX | The Best Stock Footage for Visual Effects',
  description: `Elevate your VFX projects with ActionVFX! Explore our library of 10,000+ high-quality stock footage elements and 500+ practice clips to create stunning visual effects.`,
  icon: '/favicon.ico',
  keywords: [
    'vfx stock footage',
    'action stock footage',
    'explosion stock footage',
    'fire stock footage',
    'explosion vfx',
    'explosion vfx pack',
    'vfx stock footage pack',
    'ground fire',
    'smoke stock footage',
    'vfx elements',
    'vfx assets',
    '4k effects',
    'structure fire',
    'stock footage for after effects',
    'nuke',
    'pre-keyed stock footage',
    'cg stock footage',
    'cg explosions'
  ],
  og: {
    title: 'ActionVFX | The Best Stock Footage for Visual Effects',
    description: `Elevate your VFX projects with ActionVFX! Explore our library of 10,000+ high-quality stock footage elements and 500+ practice clips to create stunning visual effects.`,
    type: 'website',
    url: 'https:/www.actionvfx.com',
    image: '/img/default-og-img.jpg'
  }
};

export const seoPricing = [
  {
    path: '/pricing',
    title: seo.title,
    description:
      'Find the perfect pricing plan for your VFX needs. From individual creators to large studios, we offer cost-effective solutions for all.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Find the perfect pricing plan for your VFX needs. From individual creators to large studios, we offer cost-effective solutions for all.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/pricing',
      image: seo.og.image
    }
  },
  {
    path: '/pricing/individual',
    title: seo.title,
    description:
      'Tailored for individual creators, explore affordable pricing options for high-quality VFX assets. Unleash your creativity without breaking the bank.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Tailored for individual creators, explore affordable pricing options for high-quality VFX assets. Unleash your creativity without breaking the bank.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/pricing/individual',
      image: seo.og.image
    }
  },
  {
    path: '/pricing/studio',
    title: seo.title,
    description:
      'Get the best value for studios with our studio pricing plans. Benefit from volume discounts, flexible licensing, and top-tier VFX assets.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Get the best value for studios with our studio pricing plans. Benefit from volume discounts, flexible licensing, and top-tier VFX assets.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/pricing/studio',
      image: seo.og.image
    }
  }
];

export const seoBasicPage = [
  {
    path: '/release-schedule',
    title: seo.title,
    description:
      "Stay ahead of the curve with ActionVFX's release schedule. Don't miss out on upcoming VFX assets and website updates.",
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        "Stay ahead of the curve with ActionVFX's release schedule. Don't miss out on upcoming VFX assets and website updates.",
      type: seo.og.type,
      url: 'https://www.actionvfx.com/release-schedule',
      image: seo.og.image
    }
  },
  {
    path: '/license',
    title: seo.title,
    description:
      "Gain clarity on our licensing terms to ensure you're using our VFX assets compliantly, whether for personal or commercial use.",
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        "Gain clarity on our licensing terms to ensure you're using our VFX assets compliantly, whether for personal or commercial use.",
      type: seo.og.type,
      url: 'https://www.actionvfx.com/license',
      image: seo.og.image
    }
  },
  {
    path: '/terms-of-service',
    title: seo.title,
    description:
      "Familiarize yourself with ActionVFX's terms of service to make the most out of our VFX assets and services.",
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        "Familiarize yourself with ActionVFX's terms of service to make the most out of our VFX assets and services.",
      type: seo.og.type,
      url: 'https://www.actionvfx.com/terms-of-service',
      image: seo.og.image
    }
  },
  {
    path: '/privacy-policy',
    title: seo.title,
    description:
      'Your privacy matters to us. Learn how ActionVFX protects your personal information and respects your data.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Your privacy matters to us. Learn how ActionVFX protects your personal information and respects your data.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/privacy-policy',
      image: seo.og.image
    }
  },
  {
    path: '/faq',
    title: seo.title,
    description:
      "Get answers to frequently asked questions about ActionVFX's products, pricing, licensing, and more.",
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        "Get answers to frequently asked questions about ActionVFX's products, pricing, licensing, and more.",
      type: seo.og.type,
      url: 'https://www.actionvfx.com/faq',
      image: seo.og.image
    }
  }
];

export const seoCollectionTiers = [
  {
    path: '/collections/free-for-subscribers?membership_tier%5Bsilver%5D=1',
    title: seo.title,
    description:
      'Exclusive free VFX assets for Silver tier members. Enhance your work at no extra cost.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Exclusive free VFX assets for Silver tier members. Enhance your work at no extra cost.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/free-for-subscribers?membership_tier%5Bsilver%5D=1',
      image: seo.og.image
    }
  },
  {
    path: '/collections/free-for-subscribers?membership_tier%5Bgold%5D=2',
    title: seo.title,
    description:
      'Unlock more value with free VFX assets for Gold tier members. Elevate your projects effortlessly.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Unlock more value with free VFX assets for Gold tier members. Elevate your projects effortlessly.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/free-for-subscribers?membership_tier%5Bgold%5D=2',
      image: seo.og.image
    }
  },
  {
    path: '/collections/free-for-subscribers?membership_tier%5Belite%5D=3',
    title: seo.title,
    description:
      'Access the ultimate collection of free VFX assets for Elite tier members. Maximum value for top-tier creators.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Access the ultimate collection of free VFX assets for Elite tier members. Maximum value for top-tier creators.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/free-for-subscribers?membership_tier%5Belite%5D=3',
      image: seo.og.image
    }
  }
];

export const seoBlog = [
  {
    path: '/blog',
    title: seo.title,
    description:
      "Stay updated with the latest trends, tutorials, and tips in the VFX world through ActionVFX's expertly curated blog.",
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        "Stay updated with the latest trends, tutorials, and tips in the VFX world through ActionVFX's expertly curated blog.",
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=podcast',
    title: seo.title,
    description:
      'Tune into ActionVFX podcasts to hear from industry experts on VFX trends, tips, and behind-the-scenes insights.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Tune into ActionVFX podcasts to hear from industry experts on VFX trends, tips, and behind-the-scenes insights.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=podcast',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=about-us',
    title: seo.title,
    description:
      'Get to know the people behind ActionVFX. Read our story, vision, and what drives us to create top-quality VFX assets.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Get to know the people behind ActionVFX. Read our story, vision, and what drives us to create top-quality VFX assets.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=about-us',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=after-effects',
    title: seo.title,
    description:
      'Master Adobe After Effects with our in-depth tutorials. Learn tips and tricks to make your VFX stand out.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Master Adobe After Effects with our in-depth tutorials. Learn tips and tricks to make your VFX stand out.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=after-effects',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=news',
    title: seo.title,
    description:
      'Stay up-to-date with the latest ActionVFX news. From product releases to company updates, we keep you informed.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Stay up-to-date with the latest ActionVFX news. From product releases to company updates, we keep you informed.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=news',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=nuke',
    title: seo.title,
    description:
      'Learn to master Nuke with ActionVFX. Our blog offers expert advice and tutorials to up your compositing game.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Learn to master Nuke with ActionVFX. Our blog offers expert advice and tutorials to up your compositing game.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=nuke',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=tutorials',
    title: seo.title,
    description:
      'Discover VFX tutorials that enhance your skills. From beginner to expert, find tips to make your projects stand out.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Discover VFX tutorials that enhance your skills. From beginner to expert, find tips to make your projects stand out.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=tutorials',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=industry-news',
    title: seo.title,
    description:
      'Keep your finger on the pulse of VFX industry news. From trends to interviews, we cover it all.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Keep your finger on the pulse of VFX industry news. From trends to interviews, we cover it all.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=industry-news',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=post-production',
    title: seo.title,
    description:
      'Improve your post-production workflow with tips, tricks, and insights from ActionVFX.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Improve your post-production workflow with tips, tricks, and insights from ActionVFX.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=post-production',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=fusion',
    title: seo.title,
    description:
      'Get the most out of Fusion with in-depth tutorials and tips. Your guide to better compositing and visual effects.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Get the most out of Fusion with in-depth tutorials and tips. Your guide to better compositing and visual effects.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=fusion',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=filmmaking',
    title: seo.title,
    description:
      'Dive into the art and science of filmmaking with our expert blog posts. Elevate your production skills today.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Dive into the art and science of filmmaking with our expert blog posts. Elevate your production skills today.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=filmmaking',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=hitfilm',
    title: seo.title,
    description:
      'Master HitFilm with our detailed tutorials and tips. Make your projects more dynamic and eye-catching.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Master HitFilm with our detailed tutorials and tips. Make your projects more dynamic and eye-catching.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=hitfilm',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=customer-projects',
    title: seo.title,
    description:
      'Get inspired by customer projects using ActionVFX. See how our assets can take your work to the next level.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Get inspired by customer projects using ActionVFX. See how our assets can take your work to the next level.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=customer-projects',
      image: seo.og.image
    }
  },
  {
    path: '/blog?category=spotlight',
    title: seo.title,
    description:
      'Meet the talented artists and professionals featured in our spotlight series. Learn their tips and tricks.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Meet the talented artists and professionals featured in our spotlight series. Learn their tips and tricks.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog?category=spotlight',
      image: seo.og.image
    }
  },
  {
    path: '/blog/runwayml-live-demo-ai-ml-easy-object-removal-with-new-inpainting-tool',
    title: seo.title,
    description:
      'Discover how AI and ML simplify object removal in VFX with our live demo of the new inpainting tool.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Discover how AI and ML simplify object removal in VFX with our live demo of the new inpainting tool.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog/runwayml-live-demo-ai-ml-easy-object-removal-with-new-inpainting-tool',
      image: seo.og.image
    }
  },
  {
    path: '/blog/become-an-actionvfx-featured-artist',
    title: seo.title,
    description:
      'Learn how you could be the next ActionVFX Featured Artist. Elevate your portfolio and gain industry recognition.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Learn how you could be the next ActionVFX Featured Artist. Elevate your portfolio and gain industry recognition.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog/become-an-actionvfx-featured-artist',
      image: seo.og.image
    }
  },
  {
    path: '/blog/5-virtual-production-youtube-channels-you-should-be-following',
    title: seo.title,
    description:
      'Stay ahead with our top 5 YouTube channels for virtual production. Essential viewing for industry professionals.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Stay ahead with our top 5 YouTube channels for virtual production. Essential viewing for industry professionals.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog/5-virtual-production-youtube-channels-you-should-be-following',
      image: seo.og.image
    }
  },
  {
    path: '/blog/sweepstakes-rules',
    title: seo.title,
    description:
      'Get all the details on how to participate in ActionVFX sweepstakes. Read the rules and guidelines here.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Get all the details on how to participate in ActionVFX sweepstakes. Read the rules and guidelines here.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog/sweepstakes-rules',
      image: seo.og.image
    }
  },
  {
    path: '/blog/5-virtual-production-youtube-channels-you-should-be-following?locale=en',
    title: seo.title,
    description:
      'Top 5 YouTube channels for virtual production, now available in English. Stay updated with global industry trends.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Top 5 YouTube channels for virtual production, now available in English. Stay updated with global industry trends.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog/5-virtual-production-youtube-channels-you-should-be-following?locale=en',
      image: seo.og.image
    }
  },
  {
    path: '/blog/runwayml-live-demo-ai-ml-easy-object-removal-with-new-inpainting-tool?locale=en',
    title: seo.title,
    description:
      'Simplify object removal in VFX using AI and ML. Watch our live demo with English commentary.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Simplify object removal in VFX using AI and ML. Watch our live demo with English commentary.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/blog/runwayml-live-demo-ai-ml-easy-object-removal-with-new-inpainting-tool?locale=en',
      image: seo.og.image
    }
  }
];

export const seoCollections = [
  {
    path: '/collections',
    title: seo.title,
    description:
      'Browse our full range of VFX assets. From explosions to weather, we have every effect to make your project shine.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Browse our full range of VFX assets. From explosions to weather, we have every effect to make your project shine.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections',
      image: seo.og.image
    }
  },
  {
    path: '/collections/free-for-subscribers',
    title: seo.title,
    description:
      'Access exclusive VFX assets free for subscribers. Upgrade your creations without extra costs.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Access exclusive VFX assets free for subscribers. Upgrade your creations without extra costs.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/free-for-subscribers',
      image: seo.og.image
    }
  },
  {
    path: '/collections/explosions/category',
    title: seo.title,
    description:
      'Create cinematic explosions with our expertly crafted VFX assets. Safe, high-quality, and realistic for all your projects.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Create cinematic explosions with our expertly crafted VFX assets. Safe, high-quality, and realistic for all your projects.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/explosions/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/fire/category',
    title: seo.title,
    description:
      'Add a fiery touch to your visuals with our extensive collection of fire VFX assets. Perfect for various scenes and settings.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Add a fiery touch to your visuals with our extensive collection of fire VFX assets. Perfect for various scenes and settings.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/fire/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/smoke/category',
    title: seo.title,
    description:
      'Enhance your scenes with realistic smoke effects. Our VFX smoke assets offer versatile use in filmmaking and animations.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Enhance your scenes with realistic smoke effects. Our VFX smoke assets offer versatile use in filmmaking and animations.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/smoke/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/debris-impact/category',
    title: seo.title,
    description:
      'Make impact scenes believable with high-quality debris VFX assets. Ideal for action scenes, simulations, and more.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Make impact scenes believable with high-quality debris VFX assets. Ideal for action scenes, simulations, and more.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/debris-impact/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/particles/category',
    title: seo.title,
    description:
      'Immerse audiences with our particle VFX assets. Great for creating atmospheres, magic effects, and enhanced visuals.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Immerse audiences with our particle VFX assets. Great for creating atmospheres, magic effects, and enhanced visuals.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/particles/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/free-fx/category',
    title: seo.title,
    description:
      'Explore our free VFX assets for creators on a budget. High-quality and royalty-free for all your creative needs.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Explore our free VFX assets for creators on a budget. High-quality and royalty-free for all your creative needs.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/free-vfx/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/gun-fx/category',
    title: seo.title,
    description:
      "Make your action scenes pop with our gun FX assets. From muzzle flashes to bullet hits, we've got you covered.",
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        "Make your action scenes pop with our gun FX assets. From muzzle flashes to bullet hits, we've got you covered.",
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/gun-fx/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/sound-fx/category',
    title: seo.title,
    description:
      'Complete your visual storytelling with our sound FX assets. High-quality audio to match your high-quality visuals.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Complete your visual storytelling with our sound FX assets. High-quality audio to match your high-quality visuals.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/sound-fx/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/water/category',
    title: seo.title,
    description:
      'Dive into our water VFX assets for your aquatic scenes. From splashes to underwater effects, make every drop count.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Dive into our water VFX assets for your aquatic scenes. From splashes to underwater effects, make every drop count.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/water/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/weather/category',
    title: seo.title,
    description:
      'Create natural atmospheres with our weather VFX assets. From rain to snow, perfect for any climate condition in your story.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Create natural atmospheres with our weather VFX assets. From rain to snow, perfect for any climate condition in your story.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/weather/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/blood-gore/category',
    title: seo.title,
    description:
      'Add realism to your action scenes with our blood and gore VFX assets. Enhance the drama, suspense, and impact.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Add realism to your action scenes with our blood and gore VFX assets. Enhance the drama, suspense, and impact.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/blood-gore/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/energy-fx/category',
    title: seo.title,
    description:
      'Elevate your projects with our energy FX assets. Ideal for sci-fi, fantasy, and much more.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Elevate your projects with our energy FX assets. Ideal for sci-fi, fantasy, and much more.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/energy-fx/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/glass/category',
    title: seo.title,
    description:
      'Shatter expectations with our glass VFX assets. Perfect for action scenes requiring breakable elements.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Shatter expectations with our glass VFX assets. Perfect for action scenes requiring breakable elements.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/glass/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/optics/category',
    title: seo.title,
    description:
      'Illuminate your work with our optics VFX assets. Create flares, glows, and more to enhance your scenes.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Illuminate your work with our optics VFX assets. Create flares, glows, and more to enhance your scenes.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/optics/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/people-crowds/category',
    title: seo.title,
    description:
      'Populate your scenes with our people and crowds VFX assets. Realistic, scalable, and versatile.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Populate your scenes with our people and crowds VFX assets. Realistic, scalable, and versatile.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/people-crowds/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/animals/category',
    title: seo.title,
    description:
      'Bring nature into your projects with our animal VFX assets. From birds to insects, animate your scenes organically.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Bring nature into your projects with our animal VFX assets. From birds to insects, animate your scenes organically.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/animals/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/electricity/category',
    title: seo.title,
    description:
      'Energize your visuals with our electricity VFX assets. Perfect for creating dynamic, electrifying effects.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Energize your visuals with our electricity VFX assets. Perfect for creating dynamic, electrifying effects.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/electricity/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/space/category',
    title: seo.title,
    description:
      'Reach for the stars with our space VFX assets. Create celestial scenes, asteroid fields, and intergalactic adventures.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Reach for the stars with our space VFX assets. Create celestial scenes, asteroid fields, and intergalactic adventures.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/space/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/variety-packs/category',
    title: seo.title,
    description:
      'Get more for less with our variety packs. A curated selection of multiple VFX assets in one package.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Get more for less with our variety packs. A curated selection of multiple VFX assets in one package.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/variety-packs/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/plants-trees/category',
    title: seo.title,
    description:
      'Add natural elements to your scenes with our plants and trees VFX assets. High-quality foliage for any environment.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Add natural elements to your scenes with our plants and trees VFX assets. High-quality foliage for any environment.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/plants-trees/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/textures/category',
    title: seo.title,
    description:
      'Enhance your scenes with our textures. High-resolution assets for added detail and realism.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Enhance your scenes with our textures. High-resolution assets for added detail and realism.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/textures/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/slime/category',
    title: seo.title,
    description:
      'Add gooey, slimy effects to your scenes with our slime VFX assets. Perfect for horror, comedy, and fantasy genres.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Add gooey, slimy effects to your scenes with our slime VFX assets. Perfect for horror, comedy, and fantasy genres.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/slime/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/miscellaneous/category',
    title: seo.title,
    description:
      'Find unique and specialized VFX assets in our miscellaneous category. Covering all your niche needs.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Find unique and specialized VFX assets in our miscellaneous category. Covering all your niche needs.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/miscellaneous/category',
      image: seo.og.image
    }
  },
  {
    path: '/collections/atmosphere/category',
    title: seo.title,
    description:
      'Create the perfect mood and setting with our atmosphere VFX assets. From fog to lens effects, add layers to your visuals.',
    icon: seo.icon,
    keywords: seo.keywords,
    og: {
      title: seo.og.title,
      description:
        'Create the perfect mood and setting with our atmosphere VFX assets. From fog to lens effects, add layers to your visuals.',
      type: seo.og.type,
      url: 'https://www.actionvfx.com/collections/atmosphere/category',
      image: seo.og.image
    }
  }
];
