import { PaymentMethod } from '../types/ui/paymentMethod';

import {
  PAY_BY_CARD_BUTTON_TEXT,
  PAY_BY_PAYPAL_BUTTON_TEXT,
  CONFIRM_FREE_PURCHASE_BUTTON_TEXT,
} from '../constants/ui';

const getPaymentButtonText = (paymentMethod: PaymentMethod, totalPrice: number): string => {
  switch (paymentMethod) {
    case 'paypal':
      return PAY_BY_PAYPAL_BUTTON_TEXT;
    case 'card':
    case 'saved_card':
    default:
      return totalPrice > 0 ? PAY_BY_CARD_BUTTON_TEXT : CONFIRM_FREE_PURCHASE_BUTTON_TEXT;
  };
}

const paymentHelpers = {
  getPaymentButtonText,
};

export default paymentHelpers;
