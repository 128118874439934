import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { SUBSCRIPTION_UPGRADE_ACTIONS } from '../../constants/actions';
const {
  SHOW_UPGRADE_SUBSCRIPTION_MODAL,
  HIDE_UPGRADE_SUBSCRIPTION_MODAL,
} = SUBSCRIPTION_UPGRADE_ACTIONS;

export const showUpgradeSubscriptionModal = (selectedPlanVariantId?: number, licenseId?: LicenseId) => ({
  type: SHOW_UPGRADE_SUBSCRIPTION_MODAL,
  selectedPlanVariantId,
  licenseId,
});

export const hideUpgradeSubscriptionModal = () => ({
  type: HIDE_UPGRADE_SUBSCRIPTION_MODAL,
});
