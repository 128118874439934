import React from "react"
import { SearchCategoryDropdownProps } from "../../types/components/search/SearchCategoryDropdown"

export const SearchCategoryDropdown: React.FC<SearchCategoryDropdownProps> = ({ dropdownRef, isDropdownOpen, dropdownAdjustments, TopNavigationAssets, handleSelectItem, handleMouseEnter, handleMouseLeave, hoveredItem, selectedItem, minimalView }) => {
  const minimalViewStyle = () => {
    return (
      <div className="flex z-50">
        <ul
          ref={dropdownRef}
          className={`${isDropdownOpen ? 'flex' : 'hidden'
            } w-full text-white border-[#FFFFFF3D] bg-a-dark-gray absolute top-[38px] flex-col px-3 py-2 rounded-[4px] drop-shadow-md left-0`}
          style={{ borderWidth: '0.5px' }}
        >
          {TopNavigationAssets.map((item, index) => (
            <li
              key={index}
              onClick={(e) => handleSelectItem(item, e)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={`flex gap-2 text-[14px] inline cursor-pointer py-2 ${selectedItem === item && 'text-a-blue'}`}
              >
                {selectedItem === item ? item.icon : item.iconOutlined}
                {item.text}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const fullViewStyle = () => {
    return (
      <div className="flex z-50">
        <ul
          ref={dropdownRef}
          className={`${isDropdownOpen ? 'flex' : 'hidden'
            } text-white border-[#FFFFFF3D] bg-a-dark-gray absolute mt-1 flex-col px-4 py-2 rounded-[4px] drop-shadow-md left-0 ${dropdownAdjustments}`}
          style={{ borderWidth: '0.5px' }}
        >
          {TopNavigationAssets.map((item, index) => (
            <li
              key={index}
              onClick={(e) => handleSelectItem(item, e)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={`flex gap-2.5 items-center hover:text-a-blue font-light whitespace-nowrap cursor-pointer flex items-center py-2`}
              >
                {hoveredItem === index ? item.icon : item.iconOutlined}
                {item.text}{' '}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    minimalView ? minimalViewStyle() : fullViewStyle()
  );
}