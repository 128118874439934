import { AccountSubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { StripePaymentMethodsList } from '../../types/api/stripe/paymentMethodsList';
import { StripePaymentMethodData } from '../../types/api/stripe/paymentMethod';
import { StripeCardData } from '../../types/api/stripe/card';
import { StripeCreatePaymentIntentResponse } from '../../types/api/stripe/createPaymentIntentResponse';
import { StripePaymentIntentConfirmData } from '../../types/api/stripe/paymentIntentConfirm';
import { StripeErrorResponse } from '../../types/api/stripe/error';
import { StripeConfirmIntentResponse } from '../../types/api/stripe/confirmIntentResponse';
import { StripeCustomerData } from '../../types/api/stripe/customer';

import { GenericResponse } from '../../types/api/Http';
import { ApiError } from '../../types/api/ErrorsTypes';
import { User } from '../../types/api/UsersTypes';

import { getFirstPaymentMethodByCustomer } from '../../helpers/stripeHelpers';
import confirmSetup from '../../helpers/stripe/confirmSetup';
import Stripe from 'stripe';
import axios from 'axios';

const getStripeDataFor = async (accountSubscription: AccountSubscriptionDetail): Promise<{ savedCard: any, stripeCustomerToken: string }> => {
  const { getFirstPaymentMethodByCustomer } = stripeHelpers;
  const stripeApiKey = process.env.STRIPE_API_KEY || Cypress?.env('STRIPE_API_KEY');
  const stripe = new Stripe(stripeApiKey, { apiVersion: '2022-11-15' });
  const subscription = await stripe.subscriptions.retrieve(
    accountSubscription.stripe_subscription_token
  );
  const customer = await stripe.customers.retrieve(subscription.customer as string) as StripeCustomerData;
  const defaultPaymentMethod = await getFirstPaymentMethodByCustomer(stripe, customer);
  return {
    savedCard: defaultPaymentMethod || null,
    stripeCustomerToken: customer.id
  };
};

const getCardsFor = async (user: User): Promise<StripePaymentMethodsList | ApiError> => {
  const payload = { stripe_customer_token: user.stripe_customer_token };
  const url = '/api/cards';
  const result = await axios.post(url, payload);
  return result.data;
};

const saveNewCardFor = async (
  user: User,
  onSuccess: Function,
  onError: Function,
  stripe: any,
  elements: any
) => {
  const { confirmSetup } = stripeHelpers;
  const customer_id = user.stripe_customer_token;
  try {
    const intentData = await axios.post('/api/setup_intent', { customer_id });
    const intent = intentData.data;
    confirmSetup(stripe, intent.setupIntentClientSecret, elements, '', 'if_required', onSuccess, onError);
  } catch (e) {
    onError(e);
  }
}

const setSelectedCardFor = async (user: User, payment_method: StripePaymentMethodData, onSuccess: Function, onError: Function, stripe: any) => {
  const { confirmSetup } = stripeHelpers;
  const customer_id = user.stripe_customer_token;
  try {
    const intentData = await axios.post('/api/setup_intent', { customer_id, payment_method: payment_method.id });
    const intent = intentData.data;
    confirmSetup(stripe, intent.setupIntentClientSecret, undefined, '', 'if_required', onSuccess, onError);
  } catch (e) {
    onError(e);
  }
}

const deletePaymentMethod = async (payment_method: StripePaymentMethodData): Promise<StripePaymentMethodData | ApiError> => {
  const payment_method_id = payment_method.id;
  const result = await axios.post('/api/delete_payment_method', { payment_method_id });
  return result.data;
}

const createPaymentIntent = async (amount: number, user: User, payment_method_id: string, onError?: Function): Promise<StripeCreatePaymentIntentResponse | StripeErrorResponse> => {
  const payload = {
    customer_id: user.stripe_customer_token,
    amount,
    payment_method_id,
  };
  try {
    const result = await axios.post('/api/payment_intent', payload);
    return result.data;
  } catch (e) {
    if (onError) onError(e);
    else return e;
  }
}

const confirmPaymentIntent = async (payment_intent_id: string, onError?: Function): Promise<StripePaymentIntentConfirmData | StripeErrorResponse> => {
  try {
    const result = await axios.post('/api/payment_intent_confirm', { payment_intent_id });
    return result.data;
  } catch (e) {
    if (onError) onError(e);
    else return e;
  }
};

const createSetupIntent = async (user: User, payment_method?: string): Promise<{setupIntentClientSecret: string} | StripeErrorResponse> => {
  const customer_id = user.stripe_customer_token;
  try {
    const payload: { customer_id: string, payment_method?: string } = { customer_id };
    if (payment_method) payload.payment_method = payment_method;
    const intentData = await axios.post('/api/setup_intent', payload);
    const intent = intentData.data;
    return intent;
  } catch (e) {
    return e;
  }
};

const confirmSetupIntent = async (clientSecret: string, elements: any, stripeInstance: any): Promise<StripeConfirmIntentResponse | StripeErrorResponse> => {
  const confirmSetupConfig: any = {
    elements,
    clientSecret,
    confirmParams: {},
    redirect: 'if_required',
  };
  return stripeInstance.confirmSetup(confirmSetupConfig).then(
    (result: StripeConfirmIntentResponse | StripeErrorResponse) => result
  );
};

const getFormattedCardBrandName = (card: StripeCardData): string => {
  return card.display_brand.split('_').join(' ');
}

const getStripeCustomerDetailsFor = async (user: User): Promise<StripeCustomerData | StripeErrorResponse> => {
  try {
    const result: GenericResponse<StripeCustomerData> = await axios.post('/api/retrieve_stripe_customer_details', { customer_id: user.stripe_customer_token });
    return result.data;
  }
  catch (e) {
    return e;
  }
}

const stripeHelpers = {
  getStripeDataFor,
  getCardsFor,
  saveNewCardFor,
  setSelectedCardFor,
  getFormattedCardBrandName,
  deletePaymentMethod,
  createPaymentIntent,
  confirmPaymentIntent,
  getStripeCustomerDetailsFor,
  // exporting these inside object for testing purposes
  confirmSetup,
  getFirstPaymentMethodByCustomer,
  createSetupIntent,
  confirmSetupIntent,
};

export default stripeHelpers;
