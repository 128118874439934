import { LicenseDetail, LicenseId, LicenseTypeDetail } from '../types/api/LicenseTypesTypes';

export const getLicenseNameById = (licenseTypes: Array<LicenseTypeDetail>, licenseId: LicenseId): string => {
  if (!licenseTypes) return '';
  for (let licenseType of licenseTypes) {
    if (licenseType.licenses.find((license) => license.id === licenseId))
      return licenseType.name;
  }
  return '';
}

export const getLicenseDetailsById = (licenseTypes: Array<LicenseTypeDetail>, licenseId: LicenseId): LicenseDetail => {
  if (!licenseTypes) return;
  for (let licenseType of licenseTypes) {
    const license = licenseType.licenses.find((license: LicenseDetail) => license.id === licenseId);
    if (license) return license;
  }
}

export const getLicenseIdByName = (licenseName: string): LicenseId => {
  switch (licenseName.toLowerCase()) {
    case 'individual': return 1;
    case 'startup': return 2;
    case 'enterprise': return 3;
    default: return;
  }
};

const licenseHelpers = {
  getLicenseNameById,
  getLicenseDetailsById,
  getLicenseIdByName,
};

export default licenseHelpers;
