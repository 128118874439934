import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { connect } from 'react-redux';
import { DownloadLimitModal4KProps } from '../../types/components/downloads/DownloadLimitModalTypes';

import { ApplicationState } from '../../types/state/storeTypes';
import { DOWNLOAD_STATUS_ACTIONS } from '../../constants/actions';

import { DownChevronIconThin, LargeWarningIcon, CheckmarkIcon, ExitIcon, DashColorIcon } from '../../modules/Icons';
import { Dialog } from '@headlessui/react';

const DownloadLimitModal4K: React.FC<DownloadLimitModal4KProps> = (props) => {
  const { downloadStatus, dispatch } = props;
  const [expandFeatList, setExpandFeatList] = useState(false);

  const router = useRouter();

  const handleExpandFeatListClick = (e) => {
    e.preventDefault();
    setExpandFeatList(!expandFeatList);
  }

  const handleClose = () => {
    dispatch({ type: DOWNLOAD_STATUS_ACTIONS.HIDE_DOWNLOAD_LIMIT_4K });
  }

  const redirect = () => router.push('/pricing');

  const chevronClass = expandFeatList ? 'transform rotate-180' : '';

  return (
    <Dialog open={downloadStatus.showDownloadLimitModal4K} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="relative w-full max-w-[896px] text-white rounded-[5px] bg-gradient-to-r from-[#0A0E0F] to-[#1A1F20]">
            <div
              className="absolute top-4 right-4 z-10 cursor-pointer"
              onClick={handleClose}>
              <ExitIcon className="stroke-white" />
            </div>

            <div className="w-full flex flex-col justify-center items-center p-4 lg:p-10">
              <section className="w-full max-w-[480px] mb-8">
                <div className="flex flex-col items-center justify-center gap-4">
                  <LargeWarningIcon />
                  <h2 className="eurostile text-white text-[18px] lg:text-[32px] md:tracking-wider md:leading-36 text-center uppercase">
                    You can’t access 4K content on your free plan
                  </h2>
                </div>
              </section>
              <section className="flex flex-col md:flex-row gap-[24px] w-full">
                <div className="flex-1 bg-[#15191B] rounded-[5px] p-6 max-w-[360px]">
                  <div className="text-[#7F898D] uppercase text-[10px] md:text-[14px] mb-4">Your current plan</div>
                  <ul className="space-y-2 text-[12px] md:text-[16px] text-[#AEB6BA]">
                    <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> 10 daily downloads from the Free VFX and Practice Footage Libraries</li>
                    <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> Limited to 2K resolution</li>
                    <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> No access to the 1500+ assets in the Essentials Catalog</li>
                    <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> Limited access to Free VFX and Practice Footage Libraries only</li>
                  </ul>
                </div>
                <div className="flex-1 bg-[#15191B] rounded-[5px] p-6">
                  <div className="text-[#7F898D] uppercase text-[10px] md:text-[14px] mb-4">Upgrade plan</div>
                  <ul className="space-y-2 text-[12px] md:text-[16px]">
                    <li className="flex items-center">
                      <CheckmarkIcon className="mr-2 flex-shrink-0" /> 
                      <span className="flex-grow">
                        20 daily downloads from the Free VFX and Practice Footage Libraries
                      </span>
                    </li>
                    <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Access all resolutions and formats</li>
                    <li className="flex items-center">
                      <CheckmarkIcon className="mr-2 flex-shrink-0" /> 
                        <span className="flex-grow">
                          Free downloads of all 1500+ assets in the Essentials Catalog
                        </span>
                    </li>
                    <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Ability to unlock premium assets with credits</li>
                    <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Team management features (Startup and Enterprise)</li>
                    {expandFeatList && (
                      <ul>
                        <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Custom Licensing Agreement (Enterprise)</li>
                        <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Dedicated Account Manager (Enterprise)</li>
                    </ul>
                    )}
                  </ul>
                  <div onClick={handleExpandFeatListClick} className="flex items-center font-light text-a-blue mt-5 text-[16px] cursor-pointer hover:text-a-light-blue stroke-a-blue hover:!stroke-a-light-blue">
                      More features
                    <DownChevronIconThin className={`inline ${chevronClass} mt-[2px] ml-2`} />
                  </div>
                </div>
              </section>
              <section className='md:flex w-full gap-[24px]'>
                <MainLinkButton
                  className="w-full !bg-[#FFFFFF1A] !text-[#7F898D] hover:!bg-[#FFFFFF33] hover:!text-white max-w-[360px] mt-4"
                  onClick={handleClose}>
                  Dismiss
                </MainLinkButton>
                <MainLinkButton
                  className="w-full mt-4"
                  variant="green"
                  onClick={() => {handleClose(); redirect();}}>
                  Upgrade
                </MainLinkButton>
              </section>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  downloadStatus: state.downloadStatus
});

export default connect(mapStateToProps)(DownloadLimitModal4K);