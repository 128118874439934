import { UpgradeSubscriptionButtonProps, UpgradeSubscriptionButtonStateProps } from '../../types/components/checkout/UpgradeSubscriptionButton';
import { StripeErrorResponse } from '../../types/api/stripe/error';
import { AxiosError } from 'axios';
import { CheckoutResponse } from '../../types/helpers/checkoutHelpers';
import { User } from '../../types/api/UsersTypes';
import { ConfirmSubscriptionOrderResponse, SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import {
  InitializePaypalSubscriptionPayload,
  InitializePaypalPaymentResponse,
} from '../../types/helpers/paypalHelpers';
import { GenericResponse } from '../../types/api/Http';

import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';

import subscribeHelpers from '../../helpers/pages/checkout/subscribe';
import checkoutHelpers from '../../helpers/checkoutHelpers';
import paypalHelpers from '../../helpers/paypalHelpers';
import navigationHelpers from '../../helpers/navigationHelpers';

import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { Loader } from '../../components/shared/Loader';

const UpgradeSubscriptionButton: React.FC<UpgradeSubscriptionButtonProps> = ({
  user,
  currentPlan,
  selectedLicenseId,
  selectedPlanVariant,
  afterSubscriptionCallback,
  // TODO test
  selectedCardId,
  paymentMethod,
  buttonText,
  doNotRedirectAfterUpgrade,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const router = useRouter();

  const { subscribe } = subscribeHelpers;
  const { checkoutUsingSavedCard, checkoutUsingNewCard } = checkoutHelpers;
  const { initializeSubscription } = paypalHelpers;
  const { navigateAway } = navigationHelpers;

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const renderError = (): React.ReactElement => {
    return error && <p className="bg-red-900 text-white p-3 text-[12px]" cy-test-id="error-block">{error}</p>
  }

  const errorHandler = (result: AxiosError | StripeErrorResponse) => {
    setLoading(false);
    setError((result as AxiosError).message || (result as StripeErrorResponse).error.message);
  }

  const checkout = () => {
    switch (paymentMethod) {
      case 'card': return useNewCard();
      case 'saved_card': return useSavedCard();
      case 'paypal': return usePayPal();
      default: return;
    }
  }

  const useNewCard = async () => {
    const submitElementsResult = await elements.submit();
    if (submitElementsResult.error) {
      return;
    }
    setLoading(true);
    const result = await checkoutUsingNewCard(user, elements, stripe);
    (result as StripeErrorResponse)?.error
       ? errorHandler(result as AxiosError | StripeErrorResponse)
       : handleSubscribe(result as CheckoutResponse);
  }

  const useSavedCard = async () => {
    setLoading(true);
    const result = await checkoutUsingSavedCard(user, stripe, selectedCardId);
    (result as StripeErrorResponse)?.error
       ? errorHandler(result as AxiosError | StripeErrorResponse)
       : handleSubscribe(result as CheckoutResponse);
  }

  const usePayPal = async () => {
    setLoading(true);
    const payload: InitializePaypalSubscriptionPayload = {
      plan_variant_id: selectedPlanVariant.id.toString(),
      license_id: selectedLicenseId.toString(),
    };
    const result = await initializeSubscription(payload, user);
    if ((result as AxiosError).status !== 200) {
      errorHandler(result as AxiosError);
      return;
    }
    const redirectUrl = (result as GenericResponse<InitializePaypalPaymentResponse>).data.redirect_url;
    navigateAway(redirectUrl);
  }

  const handleSubscribe = async (checkoutResult: CheckoutResponse) => {
    const { confirmSetupIntentData } = checkoutResult;
    const result = await subscribe(
      user,
      selectedPlanVariant,
      confirmSetupIntentData.setupIntent.id,
      selectedLicenseId,
      dispatch,
      currentPlan,
    );
    const subscription = (result as ConfirmSubscriptionOrderResponse).subscription;
    subscription
       ? afterSubscription()
       : errorHandler(result as AxiosError);
  }

  const afterSubscription = () => {
    if (afterSubscriptionCallback) afterSubscriptionCallback();
    if (doNotRedirectAfterUpgrade) return;
    router.push('/thank-you');
  }

  return (
    <div className="w-full flex flex-col gap-2" cy-test-id="upgrade-subscription-button-wrapper">
      <MainLinkButton
        variant="green"
        onClick={checkout}
        testId="upgrade-subscription-button"
        className="w-full"
        disabled={loading}
      >
        {buttonText || 'Upgrade & Pay'}
      </MainLinkButton>
      {renderError()}
      {loading && <Loader/>}
    </div>
  );
}

const mapStateToProps = (state: ApplicationState): UpgradeSubscriptionButtonStateProps => ({
  user: state.auth.user as User,
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(UpgradeSubscriptionButton);
