import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { connect } from 'react-redux';
import { DownloadLimitModalProps } from '../../types/components/downloads/DownloadLimitModalTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import { DOWNLOAD_STATUS_ACTIONS } from '../../constants/actions';

import { DownChevronIconThin, LargeWarningIcon, CheckmarkIcon, ExitIcon, DashColorIcon } from '../../modules/Icons';
import { Dialog } from '@headlessui/react';
import { Loader } from '../shared/Loader';

const DownloadLimitModal: React.FC<DownloadLimitModalProps> = (props) => {
  const { downloadStatus, dispatch, subscription } = props;
  const [expandFeatList, setExpandFeatList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [subscription]);

  const handleExpandFeatListClick = (e) => {
    e.preventDefault();
    setExpandFeatList(!expandFeatList);
  }

  const handleClose = () => {
    dispatch({ type: DOWNLOAD_STATUS_ACTIONS.HIDE_DOWNLOAD_LIMIT });
  }

  const redirect = () => router.push('/pricing');

  const chevronClass = expandFeatList ? 'transform rotate-180' : '';

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Dialog open={downloadStatus.showDownloadLimitModal} onClose={handleClose} className="relative z-40">
      <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="relative w-full md:max-w-[896px] text-white rounded-[5px] bg-gradient-to-r from-[#0A0E0F] to-[#1A1F20]">
            <div
              className="absolute top-2 md:top-4 right-2 md:right-4 z-10 cursor-pointer"
              onClick={handleClose}>
              <ExitIcon className="stroke-white" />
            </div>

            <div className="w-full flex flex-col justify-center items-center p-4 lg:p-10">
              <section className="w-full max-w-[480px] mb-8">
                <div className="flex flex-col items-center justify-center gap-1 md:gap-4">
                  <LargeWarningIcon />
                  <h2 className="eurostile text-white text-[16px] lg:text-[32px] md:tracking-wider leading-36 text-center uppercase">
                    You've reached your daily download limit
                  </h2>
                  <div className="bg-[#CB333B] h-[7px] w-full rounded-full"></div>
                </div>
                <div className="text-[#7F898D] font-light mt-2 text-start">
                  <p>
                    <span className="text-[#CB333B]">{downloadStatus.downloads}</span>/{downloadStatus.downloadLimit}
                  </p>
                </div>
              </section>

              {subscription ? (
                <SubscriptionContent handleClose={handleClose} />
              ) : (
                <NonSubscriptionContent 
                  expandFeatList={expandFeatList}
                  handleExpandFeatListClick={handleExpandFeatListClick}
                  handleClose={handleClose}
                  redirect={redirect}
                  chevronClass={chevronClass}
                />
              )}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

const SubscriptionContent = ({ handleClose }) => {
  return (
    <>
      <span className="text-[#7F898D] font-light">
        Come back tomorrow for more free downloads.
      </span>
      <MainLinkButton
        className="w-[100px] lg:max-w-[360px] !bg-[#FFFFFF1A] !text-[#7F898D] hover:!bg-[#FFFFFF33] hover:!text-white mt-8"
        onClick={handleClose}>
        Dismiss
      </MainLinkButton>
    </>
  );
}

const NonSubscriptionContent = ({ expandFeatList, handleExpandFeatListClick, handleClose, redirect, chevronClass }) => {
  return (
    <>
      <section className="flex flex-col md:flex-row gap-[24px] w-full">
        <div className="flex-1 bg-[#15191B] rounded-[5px] p-6 max-w-[360px]">
          <div className="text-[#7F898D] uppercase text-[10px] md:text-[14px] mb-4">Your current plan</div>
          <ul className="space-y-2 font-light text-[12px] md:text-[16px] text-[#AEB6BA]">
            <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> 10 daily downloads from the Free VFX and Practice Footage Libraries</li>
            <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> Limited to 2K resolution</li>
            <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> No access to the 1500+ assets in the Essentials Catalog</li>
            <li className="flex items-center"><DashColorIcon className="mr-2 flex-shrink-0" /> Limited access to Free VFX and Practice Footage Libraries only</li>
          </ul>
        </div>
        <div className="flex-1 bg-[#15191B] rounded-[5px] p-6">
          <div className="text-[#7F898D] uppercase text-[10px] md:text-[14px] mb-4">Upgrade plan</div>
          <ul className="space-y-2 font-light text-[12px] md:text-[16px]">
            <li className="flex items-center">
              <CheckmarkIcon className="mr-2 flex-shrink-0" /> 
              <span className="flex-grow">
                20 daily downloads from the Free VFX and Practice Footage Libraries
              </span>
            </li>
            <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Access all resolutions and formats</li>
            <li className="flex items-center">
              <CheckmarkIcon className="mr-2 flex-shrink-0" /> 
                <span className="flex-grow">
                  Free downloads of all 1500+ assets in the Essentials Catalog
                </span>
            </li>
            <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Ability to unlock premium assets with credits</li>
            <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Team management features (Startup and Enterprise)</li>
            {expandFeatList && (
              <ul>
                <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Custom Licensing Agreement (Enterprise)</li>
                <li className="flex items-center"><CheckmarkIcon className="mr-2 flex-shrink-0" /> Dedicated Account Manager (Enterprise)</li>
            </ul>
            )}
          </ul>
          <div onClick={handleExpandFeatListClick} className="flex items-center font-light text-a-blue mt-5 text-[16px] cursor-pointer hover:text-a-light-blue stroke-a-blue hover:!stroke-a-light-blue">
              More features
            <DownChevronIconThin className={`inline ${chevronClass} mt-[2px] ml-2`} />
          </div>
        </div>
      </section>
      <section className='flex w-full gap-[24px]'>
        <MainLinkButton
          className="w-full !bg-[#FFFFFF1A] !text-[#7F898D] hover:!bg-[#FFFFFF33] hover:!text-white max-w-[360px] mt-4"
          onClick={handleClose}>
          Dismiss
        </MainLinkButton>
        <MainLinkButton
          className="w-full mt-4"
          variant="green"
          onClick={() => {handleClose(); redirect();}}>
          Upgrade
        </MainLinkButton>
      </section>
    </>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  downloadStatus: state.downloadStatus,
  subscription: state.auth.subscription,
});

export default connect(mapStateToProps)(DownloadLimitModal);

