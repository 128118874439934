export const stripeOptions = {
  theme: 'night' as const,
  variables: {
    colorBackground: '#252B2D',
    borderRadius: '5px',
    boxShadow: 'none'
  },
  rules: {
    '.Label': {
      marginTop: '12px',
      marginRight: '16px'
    },
    '.Input': {
      boxShadow: 'none',
      marginTop: '8px',
      marginRight: '16px'
    }
  }
};

// https://docs.stripe.com/api/cards/object#card_object-brand
export const cardBrandNames = [
  'american_express',
  'diners_club',
  'discover',
  'eftpos_australia',
  'jcb',
  'mastercard',
  'union_pay',
  'visa',
];
