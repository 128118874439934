import React from 'react';
import { useRouter } from 'next/router';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { LicenseIcon, SelectedCheck, SmallLicenseIcon } from '../../modules/Icons';

import {
  BaseContentProps,
  QuestionProps,
  AgreePromptProps,
  ConfirmLicenseProps
} from '../../types/components/license-profile/LicenseConfirmationModals';

export const baseStyle =
  'text-[#AEB6BA] font-light px-[24px] py-[14px] border border-[1px] border-gray-700 rounded-[5px] hover:bg-[#FFFFFF] hover:border-[#4D5457] hover:bg-opacity-[5%] cursor-pointer';
export const activeStyle =
  'flex items-center justify-between text-white border border-a-blue rounded-[5px] font-light pl-[24px] pr-[12px] py-[10px] bg-[#00A1E0] bg-opacity-[20%] cursor-pointer';

const BaseContent: React.FC<BaseContentProps> = ({ onNext }) => (
  <div cy-test-id="base-content" className="flex flex-col gap-4 max-w-[560px] mx-auto">
    <div className="my-[84px] flex flex-col gap-[24px]">
      <p className="text-[21px] text-white">
        Before you can download assets, we need to ask you a few questions to ensure we assign you the
        correct license.
      </p>
      <p className="text-[16px] text-gray-500">
        This will take less than 2 minutes and will only need to happen once.
      </p>
    </div>
    <MainLinkButton
      cy-test-id="base-content-button"
      variant="blue"
      className="!w-full mb-16"
      onClick={onNext}>
      Start
    </MainLinkButton>
  </div>
);

const Question: React.FC<QuestionProps> = ({
  questionText,
  options,
  formData,
  onChange,
  onNext,
  onBack,
  stepIndex,
  confirmLicenseContent,
  setConfirmLicenseContent
}) => {
  const handleOptionClick = (option) => {
    onChange(`question${stepIndex}`, option);
    determineContent(option);
  };

  function determineContent(option) {
    setConfirmLicenseContent((prevContent) => {
      let updatedContent = { ...prevContent };

      if (stepIndex === 3 && option === 'Under $3 Million USD') {
        updatedContent['Annual Revenue'] = 'Under $3M';
      } else if (stepIndex === 4) {
        const formattedOption = option.replace('USD', '').trim();
        updatedContent['Annual Revenue'] = formattedOption;
      }
      return updatedContent;
    });
  }

  return (
    <div cy-test-id="question" className="flex flex-col gap-4 max-w-[560px] mx-auto">
      <div className="my-[20px] lg:my-[56px] flex flex-col">
        <p className="text-[18px] lg:text-[21px] text-white mb-[24px]">{questionText}</p>
        <div className="flex flex-col gap-4 text-[12px] lg:text-[16px]">
          {options.map((option, index) => (
            <div
              key={index}
              className={formData[`question${stepIndex}`] === option ? activeStyle : baseStyle}
              onClick={() => handleOptionClick(option)}>
              {option}
              {formData[`question${stepIndex}`] === option && <SelectedCheck />}
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        {stepIndex > 0 && (
          <MainLinkButton
            className="!w-[272px] bg-white bg-opacity-[10%] hover:bg-white hover:bg-opacity-[20%] text-white"
            onClick={onBack}>
            Back
          </MainLinkButton>
        )}
        <MainLinkButton
          variant="blue"
          className={`!w-[272px] ${
            formData[`question${stepIndex}`] && 'hover:!bg-a-light-blue !cursor-pointer'
          } hover:bg-a-blue cursor-not-allowed`}
          onClick={onNext}
          disabled={!formData[`question${stepIndex}`]}>
          Next
        </MainLinkButton>
      </div>
    </div>
  );
};

const AgreePrompt: React.FC<AgreePromptProps> = ({
  suggestedLicenseType,
  licenseDescription,
  disqualificationList,
  onNext,
  onBack
}) => (
  <div cy-test-id="agree-prompt" className="flex flex-col gap-4">
    <div className="my-[20px] lg:my-[56px] flex flex-col">
      <p className="text-[18px] lg:text-[21px] text-white mb-[24px]">
        Based on your answers, the correct license for you is:
      </p>
      <p className="flex gap-2 text-[28px] font-bold text-white mb-[24px] capitalize">
        <LicenseIcon /> {suggestedLicenseType}
      </p>
      <p className="text-[12px] lg:text-[16px] font-light text-gray-500 mb-[24px]">
        {licenseDescription}
      </p>
      {disqualificationList.length > 0 && (
        <div className="mb-[24px]">
          <p className="text-[12px] lg:text-[16px] text-white mb-2">
            You do not qualify for the "{suggestedLicenseType}" license if:
          </p>
          <ul className="list-disc pl-5 text-[12px] lg:text-[16px] font-light text-gray-500">
            {disqualificationList.map((item, index) => (
              <li key={index} className="mb-2">
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
    <div className="flex gap-4 justify-end">
      <MainLinkButton
        className="!w-[272px] bg-white bg-opacity-[10%] hover:bg-white hover:bg-opacity-[20%] text-white"
        onClick={onBack}>
        Back
      </MainLinkButton>
      <MainLinkButton
        variant="blue"
        className="!w-[424px] hover:bg-a-green !text-[12px] md:!text-[17px] p-2 md:p-0"
        onClick={onNext}>
        agree & provide licensee info
      </MainLinkButton>
    </div>
  </div>
);

const ConfirmLicense: React.FC<ConfirmLicenseProps> = ({
  confirmLicenseContent,
  onClose,
  suggestedLicenseType,
}) => {
  const router = useRouter();

  return (
    <div cy-test-id="confirm-license" className="flex flex-col gap-4 lg:max-w-[568px] lg:mx-auto">
      <div className="mt-[40px] lg:mt-[56px] flex flex-col gap-[24px]">
        <p className="text-[12px] md:text-[18px] lg:text-[21px] text-white w-3/4 md:w-full">
          Your License is ready to use! Please check details below.
        </p>
        <p className="flex items-center gap-2 text-[20px] lg:text-[28px] text-white font-bold capitalize">
          <LicenseIcon /> {suggestedLicenseType || confirmLicenseContent['ConfirmedLicenseType'] || 'Individual'}
        </p>
      </div>
      <div className="flex flex-col gap-1 text-[10px] md:text-[16px] font-light w-[200px] md:w-full">
        <div className="flex flex-col lg:flex-row gap-2">
          <p className="text-white">License ID:</p>
          <p className="text-gray-500">{confirmLicenseContent['License ID']}</p>
        </div>
        <div className="flex flex-col lg:flex-row gap-2 text-[10px] md:text-[16px]">
          <p className="text-white">Licensed to:</p>
          <p className="text-gray-500 capitalize">{confirmLicenseContent['Licensed to']}</p>
        </div>
        {suggestedLicenseType.toLowerCase() !== 'individual' || !suggestedLicenseType && (
          <>
            <div className="flex flex-col lg:flex-row gap-2 text-[10px] md:text-[16px]">
              <p className="text-white">Annual Revenue:</p>
              <p className="text-gray-500">{confirmLicenseContent['Annual Revenue']}</p>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 text-[10px] md:text-[16px]">
              <p className="text-white">Contact Person:</p>
              <p className="text-gray-500">{confirmLicenseContent['Contact Person']}</p>
            </div>
          </>
        )}
        <div className="flex flex-col lg:flex-row gap-2 text-[10px] md:text-[16px]">
          <p className="text-white">Contact Email:</p>
          <p className="text-gray-500">{confirmLicenseContent['Contact Email']}</p>
        </div>
        <div className="flex flex-col lg:flex-row gap-2 text-[10px] md:text-[16px]">
          <p className="text-white">Address:</p>
          <p className="text-gray-500">{confirmLicenseContent['Address']}</p>
        </div>
      </div>
      {/* These steps are not functional yet.
      <div className="flex flex-col gap-2 text-[16px] font-light mb-[56px] mt-[24px]">
        <div className="flex flex-row gap-2 text-a-blue cursor-pointer">
          <SmallLicenseIcon />
          <p> Download License (PDF)</p>
        </div>
        <p className="text-gray-500">
          You can change your Licensee Info in{' '}
          <a className="text-a-blue cursor-pointer">Account Details</a> at any time later.
        </p>
      </div> */}
      <MainLinkButton
        variant="blue"
        className="!w-full"
        onClick={() => (router.reload(), onClose())}>
        Close Window
      </MainLinkButton>
    </div>
  );
};

export { BaseContent, Question, AgreePrompt, ConfirmLicense };
