export const FormProgressBar = ({ className, bgColor, progressPct, cornered = false }) => (
  <div className="FormProgressBar relative pt-4">
    <div
      className={`overflow-hidden h-[4px] text-xs flex ${bgColor || 'bg-white'} ${
        !cornered && 'rounded'
      }`}>
      <div
        style={{ width: progressPct.toString() + '%' }}
        className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
          className || 'bg-a-green'
        }`}></div>
    </div>
  </div>
);
