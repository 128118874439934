import React, { useState } from 'react';
import { SmallExitIcon } from '../../modules/Icons';
import { useDispatch } from 'react-redux';

import { UserNotificationProps } from '../../types/components/shared/UserNotifications'

const typeClasses = {
  alert: 'bg-orange-900',
  info: 'bg-blue-1000',
  warning: 'bg-orange-900',
  error: 'bg-red-900',
  success: 'bg-green-1000'
};
export const genericMessageClasses =
  ' w-full p-4 text-center text-[16px] font-normal text-white bg-opacity-90 relative';

const UserNotification: React.FC<UserNotificationProps> = (props) => {
  const { notification, user } = props;
  const dispatch = useDispatch();

  const [messageVisible, setMessageVisible] = useState(true);
  if (notification == undefined || notification.body.length <= 0) return null;

  const closeMessage = () => {
    setMessageVisible(false);
    dispatch({
      type: 'ARCHIVE_NOTIFICATION',
      api: {
        method: 'PATCH',
        url: `/notifications/${notification.id}/mark_as_read`,
        headers: { authorization: user.token },
      }
    });
  };

  if (!messageVisible) {
    return <></>
  }

  return (
    <div cy-test-id="user-notification" className={typeClasses['info'] + genericMessageClasses}>
      <div className="flex items-center max-w-[900px] mx-auto">
        <div className="grow flex gap-8 mx-auto items-center">
          { notification.heading.length > 0 &&
            <div cy-test-id="heading" className="" dangerouslySetInnerHTML={{ __html: notification.heading }}></div>
          }
          <div className="grow" cy-test-id="body" dangerouslySetInnerHTML={{ __html: notification.body}}></div>
        </div>
        {/* <SmallExitIcon cy-test-id="close-icon" onClick={closeMessage} width="18" height="17" className="w-[20px] ml-auto -mt-1.5 mr-2 stroke-[#fff] fill-[#fff] cursor-pointer" /> */}
      </div>
    </div>
  );
};

export default UserNotification;
