import {
  PurchaseCreditPackButtonProps,
  PurchaseCreditPackButtonStateProps,
} from '../../types/components/paypal/PurchaseCreditPackButton';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import {
  FixedCreditPackPurchasePayload,
  CustomCreditPackPurchasePayload,
} from '../../types/api/PaypalTypes';
import {
  InitializePaypalPaymentResponse,
} from '../../types/helpers/paypalHelpers';
import { GenericResponse } from '../../types/api/Http';

import { User } from '../../types/api/UsersTypes';

import { connect } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import navigationHelpers from '../../helpers/navigationHelpers';

import { Loader } from '../../components/shared/Loader';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import InlineError from '../shared/InlineError';

import { ENV } from '../../constants/environments';

const PurchaseCreditPackButton: React.FC<PurchaseCreditPackButtonProps> = ({
  subscription,
  user,
  is_custom,
  recurring_credits,
  credit_pack_variant_id,
  disabled,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const apiUrl: string = `${ENV.api.baseURL}`;
  const { navigateAway } = navigationHelpers;

  const initiatePurchase = async () => {
    if (loading) return;
    setLoading(true);
    const payload: FixedCreditPackPurchasePayload | CustomCreditPackPurchasePayload = is_custom ? {
      is_custom: true,
      is_credit_pack_variant_order: true,
      plan_id: subscription.plan_variant.id.toString(),
      recurring_credits: recurring_credits.toString(),
    } as unknown as CustomCreditPackPurchasePayload : {
      is_credit_pack_variant_order: true,
      credit_pack_variant_id: credit_pack_variant_id.toString(),
    } as unknown as FixedCreditPackPurchasePayload;
    const url = `${apiUrl}/orders/paypal`;
    try {
      const result: GenericResponse<InitializePaypalPaymentResponse> = await axios.post(url, payload, { headers: { authorization: user.token } });
      if (!result?.data?.redirect_url) {
        setLoading(false);
        setError('An error has occured');
        return;
      }
      navigateAway(result?.data?.redirect_url);
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data?.error || 'An error has occured');
    }
  };

  const buttonDisabled = (): boolean => disabled || loading;

  return (
    <div className="w-full flex flex-col gap-2" cy-test-id="paypal-purchase-credit-pack-button-wrapper">
      <MainLinkButton
        variant="green"
        testId="paypal-purchase-credit-pack-button"
        className="w-full"
        disabled={buttonDisabled()}
        onClick={initiatePurchase}
      >
        Continue to PayPal
      </MainLinkButton>
      <InlineError errorMessage={error} />
      {loading && <Loader/>}
    </div>
  );
}

const mapStateToProps = (state: ApplicationState): PurchaseCreditPackButtonStateProps => ({
  subscription: state.auth.subscription as SubscriptionDetail,
  user: state.auth.user as User,
});

export default connect(mapStateToProps)(PurchaseCreditPackButton);
