import { DiscountBlockProps } from '../../types/components/checkout/DiscountBlock';
import { CreditPackResponse } from '../../types/api/CreditsTypes';

import { ENV } from '../../constants/environments';

export const monthlyDiscountAppliesText = 'Applies to first 6 months.';
export const yearlyDiscountAppliesText = 'Applies to first year.';

const DiscountBlock: React.FC<DiscountBlockProps> = ({
  selectedPlanVariant,
  selectedCreditPack,
}) => {
  if (ENV.displaySaleBanner !== 'true' || !selectedPlanVariant) return;

  const getFixedCreditPackPriceOff = (): number => {
    const pack = selectedCreditPack.data as CreditPackResponse;
    if (!pack.adjusted_price) return 0;
    const originalPrice = parseFloat(pack.price);
    const adjustedPrice = parseFloat(pack.adjusted_price);
    return originalPrice - adjustedPrice;
  }

  const getCustomCreditPackPriceOff = (): number => {
    const pack = selectedCreditPack.data as CreditPackResponse;
    if (!pack.adjusted_cost_per_credit) return 0;
    const originalCostPerCredit = parseFloat(pack.cost_per_credit);
    const adjustedCostPerCredit = parseFloat(pack.adjusted_cost_per_credit);
    return (originalCostPerCredit - adjustedCostPerCredit) * selectedCreditPack.customCreditPackAmount;
  }

  const getCreditPackPriceOff = (): number => {
    if (!selectedCreditPack) return 0;
    return selectedCreditPack.isCustomCreditPack
           ? getCustomCreditPackPriceOff()
           : getFixedCreditPackPriceOff();
  }

  const getSubscriptionPriceOff = (): number => {
    if (!selectedPlanVariant.adjusted_price) return 0;
    const originalPrice = parseFloat(selectedPlanVariant.price);
    const adjustedPrice = parseFloat(selectedPlanVariant.adjusted_price);
    return originalPrice - adjustedPrice;
  }

  const getPriceOff = (): string => {
    const priceOff = getSubscriptionPriceOff() + getCreditPackPriceOff();
    return priceOff.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
  }

  const getCreditPackSubtotal = (): number => {
    if (!selectedCreditPack) return 0;
    const pack = selectedCreditPack.data as CreditPackResponse;
    return selectedCreditPack.isCustomCreditPack
           ? parseFloat(pack.cost_per_credit) * selectedCreditPack.customCreditPackAmount
           : parseFloat(pack.price);
  }

  const getSubscriptionSubtotal = (): number => {
    return parseFloat(selectedPlanVariant.price);
  }

  const getSubtotal = (): string => {
    const subtotal = getSubscriptionSubtotal() + getCreditPackSubtotal();
    return subtotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
  }

  if (!parseFloat(getPriceOff())) return;

  return (
    <div className="flex flex-col gap-3 mb-3" cy-test-id="discount-block">
      <div className="flex items-center" cy-test-id="subtotal-price-block">
        <div className="text-[16px] leading-24 inter text-white" cy-test-id="label">Subtotal:</div>
        <div className="ml-auto font-400 text-[16px] text-white" cy-test-id="value">
          ${getSubtotal()}
        </div>
      </div>
      <div className="flex items-center" cy-test-id="discount-price-block">
        <div className="text-[16px] leading-24 inter text-white flex-grow" cy-test-id="label">
          Discount:
        </div>
        <span className="text-a-green mr-2" cy-test-id="value-percent">-30%</span>
        <div className="ml-auto font-400 text-[16px] text-white" cy-test-id="value-currency">
          -${getPriceOff()}
        </div>
      </div>
      <div className="text-gray-400 text-[13px] -mt-2 leading-snug" cy-test-id="discount-applies-text">
        {selectedPlanVariant.interval === 'month' ? monthlyDiscountAppliesText : yearlyDiscountAppliesText}
      </div>
    </div>
  );
};

export default DiscountBlock;
