import { ENV } from '../../constants/environments';

import { CardTypes, PayPalIcon } from '../../modules/Icons';

import { useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';
import { PaymentMethodSelectionProps, PaymentMethodSelectionStateProps } from '../../types/components/payment/PaymentMethodSelection';
import { PaymentMethod } from '../../types/ui/paymentMethod';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';

export const activeButtonClasses = 'bg-gray-900 border-a-blue';
export const inactiveButtonClasses  = 'bg-gray-800 border-gray-700';

const PaymentMethodSelection = (props: PaymentMethodSelectionProps) => {
  const { onPaymentMethodSelected, defaultSelection, user, subscription, purchaseUnderLicenseId, authenticated } = props;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(defaultSelection || null);

  const paymentMethodSelected = (method: PaymentMethod): void => {
    setSelectedPaymentMethod(method);
    if (!onPaymentMethodSelected) return;
    onPaymentMethodSelected(method);
  }

  const showPaypalOption = (): boolean => {
    return ENV.hidePaypalForSubscriptionPayments !== 'true' &&
           (!authenticated || user?.min_license_id < 3) &&
           (!subscription || subscription?.license?.id < 3) &&
           (!purchaseUnderLicenseId || purchaseUnderLicenseId < 3);
  }

  const buttonClasses = 'max-md:w-full max-md:mb-4 border p-3 rounded-[5px] mr-4 text-white';

  return (
    <fieldset cy-test-id="payment-method-selection">
      <button
        className={`${selectedPaymentMethod === 'card' ? activeButtonClasses : inactiveButtonClasses} ${buttonClasses}`}
        onClick={() => paymentMethodSelected('card')}
        cy-test-id="card-button"
      >
        Card &nbsp; <CardTypes className="inline" />
      </button>
      {showPaypalOption() && (
        <button
          className={`${selectedPaymentMethod === 'paypal' ? activeButtonClasses : inactiveButtonClasses} ${buttonClasses}`}
          onClick={() => paymentMethodSelected('paypal')}
          cy-test-id="paypal-button"
        >
          <PayPalIcon className="inline h-[20px]" />
        </button>
      )}
    </fieldset>
  )
};

const mapStateToProps = (state: ApplicationState): PaymentMethodSelectionStateProps => ({
  user: state.auth.user as User,
  subscription: state.auth.subscription as SubscriptionDetail,
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(PaymentMethodSelection);
