const formatPrice = (price: number): string => {
  return price.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
};

const formattingHelpers = {
  formatPrice,
};

export default formattingHelpers;
