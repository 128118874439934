import { useState, useEffect } from 'react';

const ActiveCampaignMessage: React.FC<any> = (props) => {
  const { type, message } = props;

  const [ borderColor, setBorderColor ] = useState<string>('');
  const [ fontColor, setFontColor ] = useState<string>('');
  const [ backgroundColor, setBackgroundColor ] = useState<string>('');

  useEffect(() => {
    switch (type) {
      case 'warning':
        setBorderColor('#8c5900');
        setFontColor('#8c5900');
        setBackgroundColor('#edd38a');
        break;
      case 'error':
        setBorderColor('rgb(147 0 0)');
        setFontColor('rgb(147 0 0)');
        setBackgroundColor('rgb(255 200 200)');
        break;
      default:
        break;
    }
  }, [type])

  const messageStyle = {
    borderColor: borderColor,
    color: fontColor,
    backgroundColor: backgroundColor,
  }

  return (
    <div
      cy-test-id="active-campagin-message"
      className="w-full block mt-5 mb-2.5 p-2 border-[1px] rounded-[4px] text-[14px] leading-[22px]"
      style={messageStyle}
    >
      { message }
    </div>
  )
}

export default ActiveCampaignMessage;
