import { connect } from 'react-redux';

import UserNotification from './UserNotification';

import {
  UserNotificationsProps,
  UserNotificationsStateProps,
} from '../../types/components/shared/UserNotifications'
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';

export const defaultContainerClass = 'notifications-container w-full top-[70px]';

const UserNotifications: React.FC<UserNotificationsProps> = (props) => {
  const { notifications, user } = props;

  if (notifications == undefined || notifications.length <= 0) return null;

  const containerClasses = defaultContainerClass;

  return (
    <div className={containerClasses} cy-test-id="notifications-container">
      {notifications.filter(n => n.notification_type === 'top_bar').map((notification, i) => (
        <UserNotification
          key={i}
          notification={notification}
          user={user}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): UserNotificationsStateProps => ({
  user: state.auth.user as User,
});

export default connect(mapStateToProps)(UserNotifications);
